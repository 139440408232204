import React from 'react';
import "./Stylesheets/Footer.css"
import footerLogo from "./Assets/Icons/logoIconPng.png"
import centeredLogo from "./Assets/Icons/centeredlogo.png"
import twitterLogo from "./Assets/Icons/twitterIcon.png"
import mediumLogo from "./Assets/Icons/mediumIcon.png"
import linkedInLogo from "./Assets/Icons/LIIconBW.png"

export function Footer() {
    return (
        <div className='footer'>
            <img className='centeredLogo' src={centeredLogo} alt='' />
            <div className='footerContainer'>
                <div className='footerLogoContainer'> 
                    <img className='footerLogo' src={footerLogo} alt='' />
                </div>
                <div className='footerNav'>
                        <ul className='footerLinks' style={{padding: 0}}>
                            <a className='footerLink' href='/'>
                                <li className='footerLinkText'>Home</li>
                            </a>
                            <a className='footerLink' href='/#/about/'>
                                <li className='footerLinkText'>About Us</li>
                            </a>
                            <a className='footerLink' href='/#/solutions/'>
                                <li className='footerLinkText'>Solutions</li>
                            </a>
                            <a className='footerLink' href='/#/clients-and-partners/'>
                                <li className='footerLinkText'>Clients and Partners</li>
                            </a>
                            <a className='footerLink' href='/#/team/'>
                                <li className='footerLinkText'>Team</li>
                            </a>
                            <a className='footerLink' href='/#/contact/'>
                                <li className='footerLinkText'>Contact</li>
                            </a>
                        </ul>
                </div>
                <div className='footerContact'>
                    <p className='footerEmailText'>info@buildedison.com</p>
                    <p className='footerAddressText'>530 5th Avenue</p>
                    <p className='footerAddressText'>9th Floor Box #5</p>
                    <p className='footerAddressText'>New York, NY 10036</p>
                </div>
                <div className='socialIconsContainer'>
                    <a href='https://twitter.com/buildedison' target="_blank" rel="noopener noreferrer">
                        <img className='socialIcon' src={twitterLogo} alt='' />
                    </a>
                    <a href='https://www.linkedin.com/company/build-edison/' target="_blank" rel="noopener noreferrer">
                        <img className='socialIcon' style={{margin: '0 36px'}}src={linkedInLogo} alt='' />
                    </a>
                    <a href='https://medium.com/build-edison' target="_blank" rel="noopener noreferrer">
                        <img className='socialIcon' src={mediumLogo} alt='' />
                    </a>
                </div>
            </div> 
        </div>
    )
}