import React from "react"
import "./Stylesheets/About.css"
import blueArrowIcon from "./Assets/Icons/arrowiconblue.png"
import whiteArrowIcon from "./Assets/Icons/arrowicon.png"
import wind from "./Assets/Icons/wind.png"
import ev from "./Assets/Icons/ev.png"
import built from "./Assets/Icons/built.png"
import grid from "./Assets/Icons/grid.png"
import projects from "./Assets/Icons/projects.png"
import strategy from "./Assets/Icons/strategy.png"
import engage from "./Assets/Icons/engage.png"
import hub from "./Assets/Icons/hub.png"
import DBE from './Assets/Logos/DBE.png'
import WBE from './Assets/Logos/WBE.png'
import WBENYS from './Assets/Logos/WBENYS.jpg'
import { Link, Element, animateScroll as scroller } from 'react-scroll'
import { ScrollToTopOnMount } from './Scroll'

export class About extends React.Component {     

    scrollTo() {
        scroller.scrollTo('scroll-to-element', {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart'
        })
    }

    render() {
        return (
            <div>
                <ScrollToTopOnMount />
                <div className="Aboutimage">
                    <div className="AboutimageMask">
                        <div className="AbouttitleText">
                            <p className="AboutmainTitle">About <span style={{ color: 'rgb(11, 108, 254)' }}>Us.</span></p>
                            <p className="Aboutsubtitle">Our mission is to build a cleaner, smarter, and empowered energy future by advancing the adoption of innovative technologies.</p>
                            <a href='/#/contact/'>
                                <div className='AboutmainContactLink'>
                                    <p className='AboutcontactLinkText'>Contact Us</p>
                                    <img className="AboutblueArrowIcon" src={blueArrowIcon} alt='' />
                                </div>
                            </a>
                            <Link activeClass="active" className="scroll" to="content" spy={true} smooth={true} duration={500} ><img className="AboutwhiteArrowIcon" src={whiteArrowIcon} alt='' /></Link>
                        </div>
                    </div>
                </div>
                <Element name="content" className="element" >
                    <div className="Aboutcontainer">
                        <h2 className="AboutcontactTagline">Build Edison<span style={{ color: 'rgb(11, 108, 254)' }}>.</span></h2>
                        <h2 className="AboutmainText" style={{marginBottom: '10px'}}>Build Edison is helping to unleash the potential of clean tech innovators, investors, and customers. We transform cleantech from a great technology into a great product or service that adds value to customers and investors, while also helping them to understand the deep value of clean tech investments as a unique opportunity and de-risk investments and adoption.</h2>
                        <a href='/#/contact/'><div className='HomemainContactLink' style={{ height: '40px' }}>
                            <p className='HomecontactLinkText' style={{ color: 'black', marginTop: '8px' }}>Contact Us</p>
                            <img className="HomeblueArrowIcon" src={blueArrowIcon} alt='' style={{ marginTop: '10px', marginLeft: '88px' }} />
                        </div></a>
                        <h2 className="AboutcontactTagline" style={{ marginTop: '100px', marginBottom: '0px' }}>Our Areas of Focus<span style={{ color: 'rgb(11, 108, 254)' }}>:</span></h2>
                        <div className="sectorscontainer">
                            <div className="sector">
                                <img className="sectorIcon" src={wind} alt='' />
                                <p className="sectorName" style={{ paddingTop: '4px'}}>Renewables <br></br>& Storage</p>
                            </div>
                            <div className="sectorRight">
                                <img className="sectorIcon" src={built} alt='' />
                                <p className="sectorName">Built Environment</p>
                            </div>
                            <div className="sector">
                                <img className="sectorIcon" src={ev} alt='' />
                                <p className="sectorName" >Transportation</p>
                            </div>
                            <div className="sectorRight">
                                <img className="sectorIcon" src={grid} alt='' />
                                <p className="sectorName">Smart Grid</p>
                            </div>
                        </div>
                        <h2 className="AboutcontactTagline" style={{ marginTop: '80px'}}>The Challenge<span style={{ color: 'rgb(11, 108, 254)' }}>.</span></h2>
                        <h2 className="AboutmainText">Cleantechs face unique challenges growing from a technology to a commercially viable company. They often have expensive solutions, complex regulatory environments, long sales cycles, difficulty finding investment, and more. We are here to help. With Build Edison, cleantechs get a team of experts to transition their technologies to commercial growth and benefit to both customers and investors.</h2>
                        <h2 className="AboutcontactTagline" style={{ marginTop: '80px' }}>Our Services<span style={{ color: 'rgb(11, 108, 254)' }}>.</span></h2>
                        <h2 className="AboutmainText" style={{ marginBottom: '30px' }}> Innovative clean technology companies need product strategies, go-to-market plans, customer engagement for demonstration projects, as well as operations and commercial processes for growth. Build Edison offers a host of advisory and consulting solutions and services to enable  startups to scale as efficiently as possible, including:</h2>
                        <div className="solutionList">
                            <div className="solutionListEntry">
                                <img className="solutionListEntryIcon" src={projects} alt='' />
                                <p className="solutionListEntryName">Projects, Pilots, & Demonstrations</p>
                            </div>
                            <div className="solutionListEntry">
                                <img className="solutionListEntryIcon" src={engage} alt='' />
                                <p className="solutionListEntryName">Market & Stakeholder Engagement</p>
                            </div>
                            <div className="solutionListEntry">
                                <img className="solutionListEntryIcon" src={strategy} alt='' />
                                <p className="solutionListEntryName">Strategy & Operations</p>
                            </div>
                            <div className="solutionListEntry">
                                <img className="solutionListEntryIcon" src={hub} alt='' />
                                <p className="solutionListEntryName">Innovation Hub</p>
                            </div>
                        </div>
                    </div>
                    <div style={{height: '260px'}} className="certificateContainer">
                        <div style={{width: '250px', margin: 'auto', padding:'30px 0 20px 0' }} className='centeredCertContainer'> 
                            <img classname='WBE' style={{width: '100px', marginRight: '50px'}} src={WBE} alt='' />
                            <img classname='DBE' style={{ width: '100px' }} src={DBE} alt='' /> 
                        </div>
                        <div style={{width: '150px', margin: 'auto'}}>
                            <img classname='WBENYS' style={{ width: '150px'}} src={WBENYS} alt='' />
                        </div>   
                    </div>    
                    <div className='AboutlowerContactButtonContainer'>
                        <a href='/#/contact/' style={{ textDecoration: 'none' }}>
                            <p className="AboutlowerContactButton" style={{ textDecoration: 'none' }}>Contact Us Now</p>
                        </a>
                    </div>
                </Element>
            </div>
        )
    }
}