import React from "react"
import "./Stylesheets/Contact.css"
import whiteArrowIcon from "./Assets/Icons/arrowicon.png"
import { Link, Element, animateScroll as scroller } from 'react-scroll'
import { ScrollToTopOnMount } from './Scroll'

export class Contact extends React.Component {

    scrollTo() {
        scroller.scrollTo('scroll-to-element', {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart'
        })
    }


    render() {
        return (
            <div>
                <ScrollToTopOnMount />
                <div className="image">
                    <div className="imageMask">
                        <div className="titleText">
                            <p className="mainTitle">Contact <span style={{ color: 'rgb(11, 108, 254)' }}>Us.</span></p>
                            <p className="subtitle">Find out how Build Edison can help you.</p>
                            <Link activeClass="active" className="scroll" to="content" spy={true} smooth={true} duration={500} ><img className="whiteArrowIcon" src={whiteArrowIcon} alt='' /></Link>
                        </div>
                    </div>
                </div>
                <Element name="content" className="element" >
                    <div className="contactPageContainer">
                        <h2 className="contactTagline">Get in touch today<span style={{ color: 'rgb(11, 108, 254)' }}>.</span></h2>
                        <h2 className="contactInfo"><br />Build Edison c/o Convene<br />530 Fifth Ave, 9th Floor Box #5<br />New York, NY 10036<br /><br />info@buildedison.com</h2>
                        <div className='contactFormContainer'>
                            <form method="POST" action="https://formspree.io/info@buildedison.com">
                                <label htmlFor='name'>Name<span style={{ color: 'rgb(11, 108, 254)' }}>*</span></label>
                                <input id='name' type='name' name='name' placeholder="" />
                                <label htmlFor='email'>Company<span style={{ color: 'rgb(11, 108, 254)' }}>*</span></label>
                                <input id='company' type="comnpany" name="company" placeholder="" />
                                <label htmlFor='email'>Email<span style={{ color: 'rgb(11, 108, 254)' }}>*</span></label>
                                <input id='email' type="email" name="email" placeholder="" />
                                <label htmlFor='message'>Message<span style={{ color: 'rgb(11, 108, 254)' }}>*</span></label>
                                <textarea id='message' name="message" placeholder=""></textarea>
                                <button className='submitButton' type="submit">Submit</button>
                            </form>
                        </div>
                            <div className='mapContainer'>
                                <iframe title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d24178.57861874653!2d-73.99723506595471!3d40.754934855533406!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c258ffd2c075b3%3A0xdb9447516b60aa7d!2s530%205th%20Ave%2C%20New%20York%2C%20NY%2010036!5e0!3m2!1sen!2sus!4v1573426030703!5m2!1sen!2sus" width="440" height="500" frameborder="0" style={{border: 0, allowfullscreen: ""}} />                   
                            </div>
                        </div>
                </Element>
            </div>
        )
    }
}