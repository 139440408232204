import React from "react"
import { TwitterTimelineEmbed } from 'react-twitter-embed'
import "./Stylesheets/News.css"
import blueArrowIcon from "./Assets/Icons/arrowiconblue.png"
import whiteArrowIcon from "./Assets/Icons/arrowicon.png"
import ArrowLeft from "./Assets/Icons/ArrowLeft.png"
import ArrowRight from "./Assets/Icons/ArrowRight.png"
import { Link, Element, animateScroll as scroller } from 'react-scroll'
import { ScrollToTopOnMount } from './Scroll'

const posts = [{
    title: "Waste Not, Want Not: Recommendations from the New York State Waste Advisory Panel",
    image: "https://miro.medium.com/max/1400/1*TMickhl3MA7-xqoPLOCI6w.jpeg",
    date: "August 26 2021",
    preview: "How can New York State turn discarded materials from over 19 million people into something useful?",
    link: "https://medium.com/build-edison/waste-not-want-not-recommendations-from-the-new-york-state-waste-advisory-panel-9892be98ac5"
},{
    title: "Summary of the Northeast Clean Energy Council’s 2021 Legislative Roundup",
    image: "https://miro.medium.com/max/1400/1*0bnL3toUr0yCvwFW6xOH0w.jpeg",
    date: "August 19 2021",
    preview: "The Northeast Clean Energy Council (NECEC) recently hosted a roundup for the 2021 legislative session.",
    link: "https://medium.com/build-edison/summary-of-the-northeast-clean-energy-councils-2021-legislative-roundup-a7f0f6d6a881"
},{
    title: "Grounded in Reality: Recommendations from the New York State Land Use and Local Government Advisory Panel",
    image: "https://miro.medium.com/max/1400/1*qtN09qz1IObRk5TAAe0xHw.jpeg",
    date: "July 22 2021",
    preview: "This article will focus on understanding the Land Use and Local Government Advisory Panel (LULG) of the CLCPA.",
    link: "https://medium.com/build-edison/grounded-in-reality-recommendations-from-the-new-york-state-land-use-and-local-government-advisory-ddc194469fd7"
},{
    title: "Using the “Internet of Things” in the Cleantech Industry",
    image: "https://miro.medium.com/max/1400/1*YyHWXiWpsYCPvvBPWpBRRA.jpeg",
    date: "July 8 2021",
    preview: "With worldwide energy consumption expected to grow by nearly 50 percent between 2018 and 2050, the demand for smarter energy solutions will continue to rise.",
    link: "https://medium.com/build-edison/using-the-internet-of-things-in-the-cleantech-industry-4ba4ab5730c8"
},{
    title: "The Changing Landscape of Cleantech Venture Investing",
    image: "https://miro.medium.com/max/2252/1*9KToqGO8PDfRGKWJNxm0nw.jpeg",
    date: "June 24 2021",
    preview: "Venture investing in energy cleantechs has always been challenging, but investment strategies are evolving to meet the challenges of scaling these critical technologies.",
    link: "https://medium.com/build-edison/the-changing-landscape-of-cleantech-venture-investing-6d7d428b73c6"
}, {
    title: "Powering Through: New York State Power Generation Advisory Panel",
    image: "https://miro.medium.com/max/3840/1*njazzapFZVnASJ0D-A905w.jpeg",
    date: "June 17 2021",
    preview: "An overview of the Power Generation Advisory Panel (TAP) of the New York Climate Leadership and Community Protection Act (CLCPA).",
    link: "https://medium.com/build-edison/powering-through-new-york-state-power-generation-advisory-panel-3bcbf035ab34"
},{
    title: "The Road to Reduced Carbon Emissions: New York State Transportation Advisory Panel",
    image: "https://miro.medium.com/max/3840/1*nenYHdAJd7lZ30x0B94xgw.jpeg",
    date: "May 27 2021",
    preview: "In this article, I focus on the Transportation Advisory Panel (TAP) of the New York Climate Leadership and Community Protection Act (CLCPA).",
    link: "https://medium.com/build-edison/the-road-to-reduced-carbon-emissions-new-york-state-transportation-advisory-panel-a7dee23da815"
},{
    title: "A Homeowner’s Guide to Small-Scale Distributed Energy Storage Systems",
    image: "https://miro.medium.com/max/936/1*KjGAW0nurhYKck2DdQbZ5g.jpeg",
    date: "May 20 2021",
    preview: "The energy storage industry has seen some of its best growth ever in terms of installed capacity during the COVID-19 pandemic.",
    link: "https://medium.com/build-edison/a-homeowners-guide-to-small-scale-distributed-energy-storage-systems-adc164c6b2d4"
},{
    title: "What it Takes to Finance an Energy Storage Project in 2021’s Evolving Marketplace",
    image: "https://miro.medium.com/max/1678/1*5-y_np2jtAkH-tmDmyqtfQ.jpeg",
    date: "April 22 2021",
    preview: "Scaled and distributed energy storage is a clear component of the future energy grid. Fully mapping the value that these systems offer as well as the financing mechanisms to enable their deployment are critical challenges to achieving widespread energy storage.",
    link: "https://medium.com/build-edison/what-it-takes-to-finance-an-energy-storage-project-in-2021s-evolving-marketplace-41062cbf53d5"
},{
    title: "Building the Future: New York State Energy Efficiency and Housing Advisory Panel",
    image: "https://miro.medium.com/max/3840/1*5WBdMHivTMbVf5FIwDDtmA.jpeg",
    date: "April 8 2021",
    preview: "In this article, we focus on the Energy Efficiency and Housing Advisory Panel (EE&H Advisory Panel) of the New York Climate Leadership and Community Protection Act (CLCPA).",
    link: "https://medium.com/build-edison/building-the-future-new-york-state-energy-efficiency-and-housing-advisory-panel-90f69c8aeff7"
},{
    title: "7 Innovative EV Charging and Battery Companies that You Need to Know About",
    image: "https://miro.medium.com/max/875/1*635t8i53Ki7fq0QA5ffSqA.jpeg",
    date: "February 17 2021",
    preview: "If you are interested in how EV charging infrastructure is evolving and accelerating the clean energy transition, we have some answers for you below!",
    link: "https://medium.com/build-edison/7-innovative-ev-charging-and-battery-companies-that-you-need-to-know-about-8a43a8e02f4"
},{
    title: "­Battery Storage in 2021 — Trends to Watch",
    image: "https://miro.medium.com/max/875/1*2QwG4t62SamyHyXoJ_0l8g.jpeg",
    date: "January 28 2021",
    preview: "During 2020 the market for energy storage systems grew to a valuation of over 1 billion in the United States, and capacity installation growth doubled compared to the year prior.",
    link: "https://medium.com/build-edison/battery-storage-in-2021-trends-to-watch-44fd819fac04"
},{
    title: "Energy Efficiency Trends: Building Envelope Innovation",
    image: "https://miro.medium.com/max/875/1*uKWkHonujUSkiPgQ0z8rmA.jpeg",
    date: "December 16 2020",
    preview: "This week I will examine another critical aspect businesses must contend with when seeking to reduce their emissions; the built environment, and more specifically building envelopes.",
    link: "https://medium.com/build-edison/cities-are-honing-in-on-reducing-built-environment-emissions-and-innovative-technologies-are-the-1b06fa4f28bb"
},{
    title: "­New York State’s Climate Leadership and Community Protection Act Unpacked",
    image: "https://miro.medium.com/max/875/1*SsG4Ktm8SRlZ6efG4nfY-A.jpeg",
    date: "December 8 2020",
    preview: "It seems that everyone is talking about climate now. Yet, one year ago, New York State put in place the country’s most ambitious statutory governance to address it.",
    link: "https://medium.com/build-edison/new-york-states-climate-leadership-and-community-protection-act-unpacked-73539fbd830f"
},{
    title: "In the New York — California Climate Policy Competition, The Golden State Leads on EVs",
    image: "https://miro.medium.com/max/875/1*g0CzAFl5n8Q08czyMjMocg.png",
    date: "November 17 2020",
    preview: "State and federal governments play a pivotal role in driving the shift to clean vehicles as they can enact legislation that enables or incentivizes businesses to switch to sustainable assets more affordably.",
    link: "https://medium.com/build-edison/in-the-new-york-california-climate-policy-competition-the-golden-state-leads-on-evs-4aa26713a546"
},{
    title: "Industry Leaders Commit to Slashing Emissions — A Clear Sign for the Clean Energy Transition",
    image: "https://miro.medium.com/max/768/1*l9qPLS1fFBUOlL7OsgljeQ.png",
    date: "November 5 2020",
    preview: "This year saw an unprecedented number of large corporations pull their heads out of the sand and admit that they need to change their behavior in order to combat climate change.",
    link: "https://medium.com/build-edison/industry-leaders-commit-to-slashing-emissions-a-clear-sign-for-the-clean-energy-transition-97c29722b0e7"
},{
    title: "3 Tips for Turning a Cleantech Solution into a Successful Business",
    image: "https://miro.medium.com/max/875/1*QQbYkOWFlJiE75LKG0FKbg.jpeg",
    date: "October 13 2020",
    preview: "Effectively scaling cleantechs is increasingly critical to meeting energy and climate goals around the world, and the strategies to realize that growth need to be developed on day one.",
    link: "https://medium.com/build-edison/3-tips-for-turning-a-cleantech-solution-into-a-successful-business-3fc7057d7ed9"
},{
    title: "If kids can’t bike safely, we will all be in deep trouble.",
    image: "https://miro.medium.com/max/875/1*tu9zFHSLZj6e2AShP42uug.png",
    date: "September 29 2020",
    preview: "My dream is to be able to get around town with my family in a way that’s safe for us and safe for the planet.",
    link: "https://medium.com/build-edison/if-kids-cant-bike-safely-we-will-all-be-in-deep-trouble-39ad2a4d0d29"
},{
    title: "Grid-interactive Efficient Buildings — The DER to Transform the Built Environment",
    image: "https://miro.medium.com/max/875/1*D39WOXajdCzo68V-eGZJ8w.jpeg",
    date: "September 24 2020",
    preview: "As customers continue to install Distributed Energy Resources (DERs) in their homes and businesses, the need for the electric grid to evolve rapidly grows. Enter the new type of DER in town — Grid-interactive Efficient Buildings (GEBs).",
    link: "https://medium.com/build-edison/grid-interactive-efficient-buildings-the-der-to-transform-the-built-environment-66c43c25a354"
},{
    title: "Ready or Not, EV Charging is Coming to New York",
    image: "https://miro.medium.com/max/875/1*9uwh97HbSfQK4fG-RXfn4A.jpeg",
    date: "August 20 2020",
    preview: "Most electric vehicle (EV) drivers wish their only worry was where to get a coffee while they charge.",
    link: "https://medium.com/build-edison/ready-or-not-ev-charging-is-coming-to-new-york-862c45262745"
},{
    title: "How to Thank Essential Workers Beyond 7 pm Claps? Let’s Improve Our Public Transportation",
    image: "https://miro.medium.com/max/1400/1*cp2J0RSw5-6mwu32a4zghg.jpeg",
    date: "June 23 2020",
    preview: "Subway ridership dropped by 90 percent when New York City became the epicenter of the coronavirus pandemic and the state issued a stay-at-home order.",
    link: "https://medium.com/build-edison/how-to-thank-essential-workers-beyond-7-pm-claps-lets-improve-our-public-transportation-d9808a5a8bc7"
},{
    title: "Leading Cutting-Edge Cleantechs to Commercialization: Interview with Build Edison’s Founder and CEO Kristin Barbato",
    image: "https://miro.medium.com/max/1400/1*QZMkRMefmw6iZ4zyFfIeFA.jpeg",
    date: "June 16 2020",
    preview: "Kristin founded Build Edison in 2018 to help innovative cleantech companies to avoid the “valley of death,” by supporting their business planning and development, as well connecting them to policymakers, regulators, utilities, and corporates. In short, Build Edison’s mission is to help cleantechs grow and reach commercialization faster.",
    link: "https://medium.com/build-edison/leading-cutting-edge-cleantechs-to-commercialization-interview-with-build-edisons-founder-and-16add3264c40"
},{
    title: "Will Coal Survive COVID-19?",
    image: "https://miro.medium.com/max/1400/1*ErEBY--7DxTl_wPWiqPVYg.jpeg",
    date: "June 3 2020",
    preview: "By the end of the 19th century, almost 80 percent of the energy mix in the U.S. consisted of coal. However, in the past month, numerous energy watchdogs have reported that coal is experiencing an unprecedented decline due to COVID-19, with many stating that it will not recover after the pandemic. How real is this prediction and what factors might be driving this trend?",
    link: "https://medium.com/build-edison/will-coal-survive-covid-19-f075cde758df"
},{
    title: "Blockchain: The Pathway to a Smarter Electricity Grid",
    image: "https://miro.medium.com/max/1400/1*2yI7BF76gKevYi8_nT9XJw.jpeg",
    date: "May 21 2020",
    preview: "A couple of years ago, blockchain was all the hype. It seemed like everyone was talking about how the technology behind the notorious bitcoin cryptocurrency would revolutionize how the world exchanged information, conducted financial transactions, and even voted in elections. The energy sector was no exception to this trend.",
    link: "https://medium.com/build-edison/blockchain-the-pathway-to-a-smarter-electricity-grid-a90ffbb3b206"
},{
    title: "Hidden Heroes: Changing Commercial Building Operations for Cleaner, Healthier Future",
    image: "https://miro.medium.com/max/1400/1*Y9LL5E7F66YyON6dsnApXw.jpeg",
    date: "May 19 2020",
    preview: "In order to reduce the spread of COVID-19, many of us are staying out of the workplaces and commercial spaces we normally frequent. Nonetheless, building operators (very underappreciated essential workers) continue to go in every day to maintain a safe and comfortable building, ready to be used tomorrow or in a couple of months.",
    link: "https://medium.com/build-edison/covid19-hidden-heroes-changing-commercial-building-operations-for-cleaner-healthier-future-1565012e53e2"
},{
    title: "Empire State Offshore Wind Ambitions",
    image: "https://miro.medium.com/max/1400/1*DfELAAsFuBSSCQM7OKHQrg.png",
    date: "May 12 2020",
    preview: "New York State’s Climate Leadership and Community Protection Act (CLCPA) set many, ambitious climate mitigation targets for the incoming decades. Among the loftiest of these goals is its offshore wind generation target: to grow the state’s offshore wind market from 0 to 9,000 MW in the next 15 years, by 2035.",
    link: "https://medium.com/build-edison/empire-state-offshore-wind-ambitions-d09bd799279b"
},{
    title: "How To Promote Clean Energy During a Pandemic? Subscribe to Community Solar",
    image: "https://miro.medium.com/max/1400/1*Re4sLivSfoklAvobUmcRug.jpeg",
    date: "May 6 2020",
    preview: "We have all seen the images on the Internet — grounded airplanes, an eerily empty Times Square, and, most notably, goats are taking over deserted towns. Just like the rest of the world, the clean energy sector has also seemingly come to a standstill due to the spread of COVID-19.",
    link: "https://medium.com/build-edison/how-to-promote-clean-energy-during-a-pandemic-subscribe-to-community-solar-6fad23344d14"
},{
    title: "A Kickstart for Natural Gas?",
    image: "https://miro.medium.com/max/1400/1*4nhLdq-OKnYIexJOHD3s3Q.jpeg",
    date: "May 1 2020",
    preview: "How the looming bankruptcy of US shale oil drillers might help American natural gas.",
    link: "https://medium.com/build-edison/a-kickstart-for-natural-gas-c41ca606cd4"
},{
    title: "Equality by Design: Green Building’s Role in Low-Income Housing",
    image: "https://miro.medium.com/max/1400/1*LJyRCTvU8DlcHzThUhAfDw.jpeg",
    date: "April 27 2020",
    preview: "If you walk across the financial district or Midtown Manhattan, you will be hard pressed to find a large building that does not feature either a LEED or ENERGY STAR certification stamp near the entrance. Can we say the same for other types of buildings across New York City, particularly low-income multifamily homes?",
    link: "https://medium.com/build-edison/equality-by-design-green-buildings-role-in-low-income-housing-3f9015ad1537"
},{
    title: "Electric Vehicles Will Thrive With or Without Trump’s Fuel Economy Rollback",
    image: "https://miro.medium.com/max/1400/1*7lm0Q_H12qlF8GnoKtqQsQ.jpeg",
    date: "April 20 2020",
    preview: "Late last month, after a two-year battle that embroiled the U.S. Environmental Protection Agency (EPA) and California and divided the U.S. automotive industry, the Trump Administration weakened vehicle greenhouse gas (GHG) and fuel economy standards through the Safer Affordable Fuel Efficient (SAFE) Vehicles Rule.",
    link: "https://medium.com/build-edison/electric-vehicles-will-thrive-with-or-without-trumps-fuel-economy-rollback-5af8165a22c5"
}, {
    title: "How Energy Markets Stack Up in a Global Pandemic and What’s Ahead?",
    image: "https://miro.medium.com/max/1400/1*bRgLhriZhdZLHIqI6blb8A.jpeg",
    date: "April 17 2020",
    preview: "Almost every market in the world is seeing unprecedented shifts in the era of COVID-19, from growth in the toilet paper and bean sales to market contraction for small businesses. Energy markets are no exception, undergoing unparalleled price changes and demand shifts.",
    link: "https://medium.com/build-edison/how-energy-markets-stack-up-in-a-global-pandemic-and-whats-ahead-91e4a0565c0f"
},{
    title: "The Path to Grid Reliability and Resiliency? Renewable Energy",
    image: "https://miro.medium.com/max/1400/1*FVl9acNBssY91skuqoybDw.jpeg",
    date: "April 14 2020",
    preview: "The cause behind one of the worst power outages in North American history? Exceeded grid capacity on a particularly hot summer day.",
    link: "https://medium.com/build-edison/the-path-to-grid-reliability-and-resiliency-renewable-energy-ff408c673ed2"
},{
    title: "What’s New in Building Energy Efficiency?",
    image: "https://miro.medium.com/max/1400/1*P6ej-_WIcvoodSx-b9NPug.jpeg",
    date: "April 7 2020",
    preview: "You can call it a market jackpot when you find synergies between climate change mitigation efforts and consumer benefits. Energy efficiency is that jackpot.",
    link: "https://medium.com/build-edison/whats-new-in-building-energy-efficiency-c98028fef4c5"
},{
    title: "Five Reasons We Don’t Do Work Video Calls (And One Reason We Do!)",
    image: "https://miro.medium.com/max/906/1*1JYNAuOqsmXQnqOERtNlLw.png",
    date: "March 30 2020",
    preview: "For those of us lucky enough to work from home (WFH for the newly initiated), I’ve seen many people advocate the benefits of video calls for work.",
    link: "https://medium.com/build-edison/five-reasons-we-dont-do-work-video-calls-and-one-reason-we-do-f290bf1e6683"
},{
    title: "Hydrogen: A Clean Energy Source of the Future?",
    image: "https://miro.medium.com/max/1400/1*RZnUlOcYpPAGlOtCQh8Q_A.jpeg",
    date: "March 27 2020",
    preview: "Jules Verne thought in 1874: “water is the coal of the future.” Is it really?",
    link: "https://medium.com/build-edison/hydrogen-a-clean-energy-source-of-the-future-855351140b02"
},{
    title: "Build Edison Expands its Built Environment Expertise",
    image: "https://miro.medium.com/max/1400/1*aiQq4ZVuV0h8T-1gtEBMsw.jpeg",
    date: "March 25 2020",
    preview: "Since the beginning of 2020, the Build Edison team continued to grow and expand its expertise. On February 3, we brought onboard Zach Sussman to lead the Built Environment division of Build Edison’s cleantech advisory practice.",
    link: "https://medium.com/build-edison/build-edison-expands-its-built-environment-expertise-e2920c68dfbf"
},{
    title: "New York vs. California: Who’s Ahead on Climate Policy?",
    image: "https://miro.medium.com/max/3241/1*4HEulyS3btlYhI2-KiCObQ.jpeg",
    date: "March 17 2020",
    preview: "California has historically been a leader in clean energy and technology innovation in the United States. In 2006...",
    link: "https://medium.com/build-edison/new-york-vs-california-whos-ahead-on-climate-policy-5a612310d4b3"
},{
    title: "Ramping Up Energy Storage: Are We on Track?",
    image: "https://miro.medium.com/max/3186/1*qXn3HqKc3Vn3z66UVmIaVA.jpeg",
    date: "March 10 2020",
    preview: "This past year has been characterized by New York State’s legislative action toward an emissions-free future. With ambitions for a 100% clean electricity grid by mid-century, reliable renewable dispatch will be a key challenge of the next few decades.",
    link: "https://medium.com/build-edison/ramping-up-energy-storage-are-we-on-track-2ab723838a32"
},{
    title: "Are the Risks of Climate Change Reshaping Finance?",
    image: "https://miro.medium.com/max/1008/1*Lbf5tQjPQHHDFS_aNzX7rw.png",
    date: "March 2 2020",
    preview: "BlackRock’s recent announcement on the importance of factoring climate risk into investment decisions sent waves through the financial sector.",
    link: "https://medium.com/build-edison/are-the-risks-of-climate-change-reshaping-finance-7ed6379a8d7b"
},{
    title: "New York City’s Artful Path to Net Zero Future",
    image: "https://miro.medium.com/max/1810/1*CT50MeB_zaQcrLArmxRBYQ.png",
    date: "February 22 2020",
    preview: "New York City has committed to an unprecedented path to its emissions reduction. The challenges along this journey will be vast, as the city aims to balance both economic prosperity and climate justice.",
    link: "https://medium.com/build-edison/new-york-citys-artful-path-to-net-zero-future-a7f966e61065"
},{
    title: "Seeing with 2020 vision in cleantech",
    image: "https://miro.medium.com/max/4375/1*1F46zQS-HtuUsebBGhDt2w.jpeg",
    date: "January 15 2020",
    preview: "5 trends that will make cleantech stand out this year.",
    link: "https://medium.com/build-edison/seeing-with-2020-vision-in-cleantech-e23be206f709"
},{
    title: "The Importance of Getting First-Time Project Financing Right",
    image: "https://miro.medium.com/max/5360/1*bZkW2t4441hAjW9cZiXT0Q.jpeg",
    date: "January 5 2020",
    preview: "Demonstration projects showcase the potential of lab tested technologies in real world environments, and will be critical to efficienty managing the energy transition.",
    link: "https://medium.com/build-edison/the-importance-of-getting-first-time-project-financing-right-359e9218e905"
},{  
    title: "Disruptive Technology in a Regulated Market: Will Utilities Suffer the Uber Effect?",
    image: "https://miro.medium.com/max/6840/1*oyirkxAYG1e5J7ucSWD4ZA.jpeg",
    date: "December 4 2019",
    preview: "New technologies are threatening the traditional utility model. Utilities and regulators will need to adapt.",
    link: "https://medium.com/build-edison/disruptive-technology-in-a-regulated-market-will-utilities-suffer-the-uber-effect-e461f3f82bdf"
},{
    title: "Switched Source is making the US grid more efficient and reliable",
    image: "https://miro.medium.com/max/2813/1*ERCKNAvtXJsffJPqQ5xiTg.jpeg",
    date: "December 4 2019",
    preview: "Switched Source and its research partners at Michigan State University have designed a simple solution to improve the operations of the existing grid.",
    link: "https://medium.com/build-edison/switched-source-is-making-the-us-grid-more-efficient-and-reliable-f46b3d2c5200"
},]

export class News extends React.Component {     

    constructor() {
        super()

        this.state = {
            page: 0,
            postOne: posts[0],
            postTwo: posts[1],
            postThree: posts[2],
        }

        this.pageUp = this.pageUp.bind(this)
        this.pageDown = this.pageDown.bind(this)
        this.calculatePostNumbers = this.calculatePostNumbers.bind(this)

    }    

    scrollTo() {
        scroller.scrollTo('scroll-to-element', {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart'
        })
    }

    calculatePostNumbers(pageNumber) {
        if (pageNumber * 3 + 2 > posts.length ) {
            this.setState({ postThree: posts[posts.length - 1] })
            this.setState({ postTwo: posts[posts.length - 2] })
            this.setState({ postOne: posts[posts.length - 3] })
        } else {
            this.setState({ postThree: posts[pageNumber * 3 + 2] })
            this.setState({ postTwo: posts[pageNumber * 3 + 1] })
            this.setState({ postOne: posts[pageNumber * 3] })
        }
    }

    pageUp() {
        if (this.state.page * 3 + 3 < posts.length) {
            var newPage = this.state.page + 1
            this.setState({ page: newPage })
            this.calculatePostNumbers(newPage)
        }
    }

    pageDown() {
        if (this.state.page > 0) {
            var newPage = this.state.page - 1
            this.setState({ page: newPage })
            this.calculatePostNumbers(newPage)
        }
    }

    render() {
        return (
            <div>
                <ScrollToTopOnMount />
                <div className="Newsimage">
                    <div className="NewsimageMask">
                        <div className="NewstitleText">
                            <p className="NewsmainTitle">News and <span style={{ color: 'rgb(11, 108, 254)' }}>Blogs.</span></p>
                            <p className="Newssubtitle">See what Build Edison is working on today.</p>
                            <a href='/#/contact/'>
                                <div className='NewsmainContactLink'>
                                    <p className='NewscontactLinkText'>Contact Us</p>
                                    <img className="NewsblueArrowIcon" src={blueArrowIcon} alt='' />
                                </div>
                            </a>
                            <Link activeClass="active" className="scroll" to="content" spy={true} smooth={true} duration={500} ><img className="NewswhiteArrowIcon" src={whiteArrowIcon} alt='' /></Link>
                        </div>
                    </div>
                </div>
                <Element name="content" className="element" >
                    <div className="Newsmaincontainer">
                        <h2 className="NewscontactTagline" style={{ marginTop: '80px', marginBottom: '10px' }}>News and Blogs<span style={{ color: 'rgb(11, 108, 254)' }}>.</span></h2>
                        <p style={{ textAlign: "left", marginTop: 0, marginBottom: '50px', fontWeight: 300, fontFamily: 'Source Sans Pro' }}>Follow Build Edison on <a style={{ textDecoration: 'none', color: 'rgb(11, 108, 254)', fontWeight: 600 }} href='https://www.linkedin.com/company/build-edison/' target="_blank" rel="noopener noreferrer">LinkedIn</a> and <a style={{ textDecoration: 'none', color: 'rgb(11, 108, 254)', fontWeight: 600 }} href='https://twitter.com/buildedison' target="_blank" rel="noopener noreferrer">Twitter</a>. For media inquires please contact us at info@buildedison.com</p>
                        <div className="NewsandBlogcontainer">
                            <a className="ArticleLink" href={this.state.postOne.link} target="_blank" rel="noopener noreferrer">
                                <div className="Articlecontainer">
                                    <img className="Articleimage" src={this.state.postOne.image} alt='' />
                                    <div className="Articletextcontainer">
                                        <h4 className='ArticleTitle'>{this.state.postOne.title}</h4>
                                        <h4 className='ArticleDate'>{this.state.postOne.date}</h4>
                                        <h4 className='ArticleSample'>{this.state.postOne.preview}</h4>
                                    </div>
                                </div>
                            </a>
                            <a className="ArticleLink" href={this.state.postTwo.link} target="_blank" rel="noopener noreferrer">
                                <div className="Articlecontainer">
                                    <img className="Articleimage" src={this.state.postTwo.image} alt='' />
                                    <div className="Articletextcontainer">
                                        <h4 className='ArticleTitle'>{this.state.postTwo.title}</h4>
                                        <h4 className='ArticleDate'>{this.state.postTwo.date}</h4>
                                        <h4 className='ArticleSample'>{this.state.postTwo.preview}</h4>
                                    </div>
                                </div>
                            </a>
                            <a className="ArticleLink" href={this.state.postThree.link} target="_blank" rel="noopener noreferrer">
                                <div className="Articlecontainer">
                                    <img className="Articleimage" src={this.state.postThree.image} alt='' />
                                    <div className="Articletextcontainer">
                                        <h4 className='ArticleTitle'>{this.state.postThree.title}</h4>
                                        <h4 className='ArticleDate'>{this.state.postThree.date}</h4>
                                        <h4 className='ArticleSample'>{this.state.postThree.preview}</h4>
                                    </div>
                                </div>
                            </a>
                            <div className='ArrowContainer'>
                                <img className='leftPageArrow' src={ArrowLeft} alt='' onClick={this.pageDown}/>
                                <img className='rightPageArrow' src={ArrowRight} alt='' onClick={this.pageUp}/>
                            </div>
                        </div>
                        <div className="Timelinecontainer">
                            <TwitterTimelineEmbed
                                sourceType="profile"
                                screenName="BuildEdison"
                                options={{ height: '1610', width: '100%' }}
                            />
                        </div>
                    </div>
                    <div className='newsFooterSpacing'></div>
                </Element>
            </div>
        )
    }
}