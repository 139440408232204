import React from "react";
import { HashRouter as Router, Route, Link } from "react-router-dom";
import "./Stylesheets/Navbar.css"
import "./Stylesheets/hamburgers.css"
import logo from "./Assets/Icons/logo.png"
import logoIcon from "./Assets/Icons/logoIcon.png"
import { Home } from "./Home"
import { About } from "./About"
import { Solutions } from "./Solutions"
import { Clients } from "./Clients"
import { Team } from "./Team"
import { Contact } from "./Contact"
import { Footer } from "./Footer"
import { News } from "./News"

export class AppRouter extends React.Component {

    constructor() {
        super()

        this.state = {
            navbar: 1,
            menuHeight: 0,
            hamburgerClass: 'hamburger hamburger--slider'
        }

        this.toggleMenu = this.toggleMenu.bind(this)
    
    }

    toggleMenu() {
        if(this.state.menuHeight) {
            this.setState({ menuHeight: 0 })
            this.setState({ hamburgerClass: 'hamburger hamburger--slider'})
        } else {
            this.setState({ menuHeight: '290px' })
            this.setState({ hamburgerClass: 'hamburger hamburger--slider is-active' })
        }
    }

    checkNavbar() {
        if(window.innerWidth < 1110) {
            this.setState({ navbar: 0 })
        } else if (window.innerWidth > 1110) {
            this.setState({ navbar: 1 })
        }
    }

    componentDidMount() {
        this.checkNavbar()
        window.addEventListener("resize", this.checkNavbar.bind(this))
    }

    componentWillUnmount() {
        window.addEventListener("resize", this.checkNavbar.bind(this))
    }

    buildNavbar() {
        if(this.state.navbar) {
            return (
                <Router>
                    <div className="navbarBackground">
                        <div className="navbar">
                            <nav>
                                <Link to="/"><img className="logo" src={logo} alt="Build Edison" /></Link>
                                <ul className="links">
                                    <li>
                                        <Link to="/contact/" className="navLinkContact" style={{ textDecoration: 'none' }}>Contact</Link>
                                    </li>
                                    <li>
                                        <Link to="/news/" className="navLink" style={{ textDecoration: 'none' }}>News</Link>
                                    </li>
                                    <li>
                                        <Link to="/team/" className="navLink" style={{ textDecoration: 'none' }}>Team</Link>
                                    </li>
                                    <li>
                                        <Link to="/clients-and-partners/" className="navLink" style={{ textDecoration: 'none' }}>Clients and Partners</Link>
                                    </li>
                                    <li>
                                        <Link to="/solutions/" className="navLink" style={{ textDecoration: 'none' }}>Solutions</Link>
                                    </li>
                                    <li>
                                        <Link to="/about/" className="navLink" style={{ textDecoration: 'none' }}>About Us</Link>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>

                    
                    <Route path="/about/" component={About} />
                    <Route path="/solutions/" component={Solutions} />
                    <Route path="/clients-and-partners/" component={Clients} />
                    <Route path="/team/" component={Team} />
                    <Route path="/news/" component={News} />
                    <Route path="/contact/" component={Contact} />
                    <Route path="/" exact component={Home} />

                </Router>
            );
        } else {
            return (
                <div>
                    <div className='smallNavbar'>
                        <button id="menubutton" className={this.state.hamburgerClass} type="button" onClick={this.toggleMenu}>
                            <span className="hamburger-box">
                                <span className="hamburger-inner"></span>
                            </span>
                        </button>
                        <div className='smallIconContainer'>
                            <a href='/'><img className='smallIcon' src={logoIcon} alt=''/></a>
                        </div>
                    </div>
                    <div>
                        <Router>
                            <div className='menuContainer' style={{ height: this.state.menuHeight, overflow: 'hidden'}}>
                                <nav>
                                    <ul className="menuLinks" style={{ marginLeft: 0, marginTop: '8px'}}>
                                        <li onClick={this.toggleMenu} style={{marginTop: '0'}}>
                                            <Link to="/" className="menuLink" style={{ textDecoration: 'none' }}>Home</Link>
                                        </li>
                                        <li onClick={this.toggleMenu}>
                                            <Link to="/about/" className="menuLink" style={{ textDecoration: 'none' }}>About Us</Link>
                                        </li>
                                        <li onClick={this.toggleMenu}>
                                            <Link to="/solutions/" className="menuLink" style={{ textDecoration: 'none' }}>Solutions</Link>
                                        </li>
                                        <li onClick={this.toggleMenu}>
                                            <Link to="/clients-and-partners/" className="menuLink" style={{ textDecoration: 'none' }}>Clients and Partners</Link>
                                        </li>
                                        <li onClick={this.toggleMenu}>
                                            <Link to="/team/" className="menuLink" style={{ textDecoration: 'none' }}>Team</Link>
                                        </li >
                                        <li className='testli' onClick={this.toggleMenu} style={{ marginBottom: '25px' }}>
                                            <Link to="/news/" className="menuLink" style={{ textDecoration: 'none' }}>News</Link>
                                        </li>
                                        <li style={{ margintop: '40px' }} onClick={this.toggleMenu}>
                                            <Link to="/contact/" className="menuLinkContact" style={{ textDecoration: 'none'}}>Contact</Link>
                                        </li>
                                    </ul>
                                </nav>
                            </div>

                            
                            <Route path="/about/" component={About} />
                            <Route path="/solutions/" component={Solutions} />
                            <Route path="/clients-and-partners/" component={Clients} />
                            <Route path="/team/" component={Team} />
                            <Route path="/news/" component={News} />
                            <Route path="/contact/" component={Contact} />
                            <Route path="/" exact component={Home} />

                        </Router>
                    </div>
                    <Footer />
                </div>
            )
        }
    }

    render() {
        return (
            this.buildNavbar()
        )
    }
}
