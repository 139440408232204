import React from "react"
import "./Stylesheets/Team.css"
import './Stylesheets/Popup.css'
import blueArrowIcon from "./Assets/Icons/arrowiconblue.png"
import whiteArrowIcon from "./Assets/Icons/arrowicon.png"
import close from "./Assets/Icons/close.png"
import LIicon from "./Assets/Icons/LIicon.png"
import Kristin from "./Assets/Employees/Kristin.png"
import Max from "./Assets/Employees/Max.png"
import DanW from './Assets/Employees/DanW.png'
import arthurK from './Assets/Employees/arthurK.png'
import shesiX from './Assets/Employees/shesiX.png'
import carlaT from './Assets/Employees/carlaT.png'
import kenJ from './Assets/Employees/kenJ.png'
import kentG from './Assets/Employees/kentG.png'
import micheleD from './Assets/Employees/micheleD.png'
import Zach from './Assets/Employees/Zach.jpg'
import RobertG from './Assets/Employees/RobertG.png'
import Barbara from './Assets/Employees/Barbara.jpg'
import BarbaraL from './Assets/Employees/BarbaraL.jpg'
import MaxMel from './Assets/Employees/maxmel.jpg'
import Meg from './Assets/Employees/meg.jpeg'
import { Link, Element, animateScroll as scroller } from 'react-scroll'
import { ScrollToTopOnMount } from './Scroll'
import Meaghan from './Assets/Employees/Meaghan.jpg'

const employeeInfo = {
    Kristin: {
        name: 'Kristin Barbato',
        title: 'Founder and CEO',
        bio: 'With 25 years in energy, Kristin Barbato has been an executive in utilities, government, and operations. As the Chief Energy Management Officer of NYC, she managed the US’s largest municipal energy operations ($1.3B). Now as CEO of Build Edison, she helps early-stage energy companies get to scaled commercialization faster. Also an adjunct professor at Columbia University, her broad network of experts and energy entrepreneurs provide market insights to move strategies to commercial action.',
        link: 'https://www.linkedin.com/in/kristin-barbato-7995b22/'
    },
    Meaghan: {
        name: 'Meaghan Yorro',
        title: 'Director of Commercial Strategy',
        bio: 'Meaghan Yorro offers 15 years of experience executing energy market strategy and transforming operations for Fortune 100 companies, governments and technology startups across the energy value chain. This includes lead roles guiding companies on strategic commercialization opportunities with IHS Markit and Kissinger McLarty Associates. From within industry operations at Baker Hughes and Forum Energy Technologies, Meaghan spearheaded several global oilfield manufacturing initiatives including digital transformation programs and product management at the field-level. Her career journey further includes Head of Growth and Marketing for two energy Artificial Intelligence (AI) startups, where she led strategic portfolio and product roadmap development.',
        link: 'linkedin.com/in/meaghanyorro'
    },
    Max: {
        name: 'Max McCafferty',
        title: 'Chief Operating Officer',
        bio: "Max joined Build Edison in 2018, and his background is in clean energy innovation, the commercialization of energy technologies, and quantitative analysis. He leverages his experience to provide Build Edison’s clients with strategies to develop energy innovation programs, scale cleantech solutions, and analyze potential investment opportunities. At Build Edison, Max has focused on multiple sides of energy solutions and innovation, developing innovation programs and strategies as well as enabling energy startups to scale, and has worked with clients of all sizes, spanning early stage cleantechs, corporations, utilities, investors, and governments.",
        link: 'https://www.linkedin.com/in/max-mccafferty-b6724317a/'
    },
    Zach: {
        name: 'Zach Sussman',
        title: 'Director of Built Environment',
        bio: "Before joining Build Edison Zach Sussman was a Senior Specialist at Con Edison, the Connected Devices Program Manager and a member of the Pilots and Emerging Technology Team in Energy Efficiency & Demand Management.  Zach evaluates and runs pilots around the smart home and smart building technologies to find benefit for both customers and the utility. Prior to working at Con Edison, Zach worked for a boutique regional energy and sustainability consulting firm, working on energy efficiency and sustainability strategy for a number of healthcare, higher education, and commercial customers.  Additionally, Zach worked at NYU Langone Health as a member of the energy team, evaluating and installing energy efficiency measures throughout the organization. ",
        link: 'https://www.linkedin.com/in/zach-sussman-283a8954/'
    },
    Barbara: {
        name: 'Barbara Kates-Garnick',
        title: 'Head of Regulatory Strategy',
        bio: "Barbara Kates-Garnick is Professor of Practice at The Fletcher School. Most recently she served as the Undersecretary of Energy for the Commonwealth of Massachusetts where she was responsible for guiding energy policy. She also served as the Co-chair of Massachusetts’ Global Warming Solutions Act Implementation Advisory Committee that oversees the implementation of the Commonwealth’s Global Warming Solutions Act. She has had an extensive career in energy, environment, and clean technology that has spanned the private and public sectors and included the creation of a clean technology incubator at New York University Polytechnic School of Engineering. She served as corporate officer at a major U.S. utility, a consultant on strategic energy initiatives, and as a public utility regulator. ",
        link: ''
    },
    BarbaraL: {
        name: 'Barbara Lantz',
        title: 'Communications Manager',
        bio: "Barbara's background is in marketing and content creation. She is currently pursuing a Master of International Affairs at Columbia University with a concentration in Energy and Environment. Previously, she worked for Major League Baseball. She received a BA in International Relations and a BBA in Marketing from William & Mary.",
        link: 'https://www.linkedin.com/in/barbaralantz/'
    },
    MaxMel: {
        name: 'Max Melnick',
        title: 'Senior Associate',
        bio: "Max joined Build Edison in 2021 with a background in green hydrogen, US-based clean energy accelerators, the biobased economy, and advanced grid infrastructure. Before joining Build Edison, Max consulted for clean energy companies where he gained practical and technical experience with various critical clean energy topics and initiatives. ",
        link: 'https://www.linkedin.com/in/max-melnick-058101140/'
    },
    Meg: {
        name: 'Meg McGriff',
        title: 'Contributing Author',
        bio: "Meg’s background is in social media and sustainability related issues. She is currently earning a Bachelor’s of Arts in Journalism and a Bachelor’s of Arts in Geography with a minor in Sustainability at the University of Denver. Previously, she has interned for Conservation Colorado on a climate action campaign.",
        link: 'https://www.linkedin.com/in/meg-mcgriff/'
    }
}


export class Team extends React.Component {

    constructor() {
        super()

        this.state = {
            opacity: 0,
            zIndex: -1,
            maskClassName: '',
            img: '',
            name: '',
            title: '',
            bio: '',
            link: '',
            id: '',
        }

        this.openBio = this.openBio.bind(this)
        this.closeBio = this.closeBio.bind(this)

    }

    openBio(name, stringName) {
        this.setState({ 
            opacity: 1,
            zIndex: 10,
            maskClassName: 'popupMask',
            img: name,
            name: employeeInfo[stringName]['name'],
            title: employeeInfo[stringName]['title'],
            bio: employeeInfo[stringName]['bio'],
            link: employeeInfo[stringName]['link'],
            id: stringName
        })
        
    }

    closeBio() { 
        this.setState({
            opacity: 0,
            zIndex: -1,
            maskClassName: '',
        })
    }

    scrollTo() {
        scroller.scrollTo('scroll-to-element', {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart'
        })
    }

    render() {
        return (
            <div>
                <ScrollToTopOnMount/>
                <div className="Teamimage">
                    <div className="TeamimageMask">
                        <div className="TeamtitleText">
                            <p className="TeammainTitle">Our <span style={{ color: 'rgb(11, 108, 254)' }}>Team.</span></p>
                            <p className="Teamsubtitle">Get to know the talented individuals who power Build Edison.</p>
                            <a href='/#/contact/'>
                                <div className='TeammainContactLink'>
                                    <p className='TeamcontactLinkText'>Contact Us</p>
                                    <img className="TeamblueArrowIcon" src={blueArrowIcon} alt='' />
                                </div>
                            </a>
                            <Link activeClass="active" className="scroll" to="content" spy={true} smooth={true} duration={500} ><img className="TeamwhiteArrowIcon" src={whiteArrowIcon} alt='' /></Link>
                        </div>
                    </div>
                </div>
                <Element name="content" className="element" >
                    <div className={this.state.maskClassName} onClick={() => this.closeBio()}></div>
                    <div className='bio' id={this.state.id} style={{opacity: this.state.opacity, zIndex: this.state.zIndex}}>
                        <img src={close} alt='' onClick={this.closeBio} className='closeButtonBio' />
                        <img className='popupHeadshot' src={this.state.img}  alt='' ></img>
                        
                        <div className='popupText'>
                            <h2 className="popupName"><a className='popupLink' href={this.state.link} target="_blank" rel="noopener noreferrer">{this.state.name}</a><span style={{ color: 'rgb(11, 108, 254)' }}>.</span></h2>
                            <h2 className="popupTitle">{this.state.title}</h2>
                            <h2 className="popupBio">{this.state.bio}</h2>
                        </div>                        
                    </div>
                    <div className="Teamcontainer" >
                        <h2 className="TeamSectionTitle" style={{ marginLeft: '15px' }}>Team<span style={{ color: 'rgb(11, 108, 254)' }}>.</span></h2>
                        <div className="employeeContainer" onClick={() => this.openBio(Kristin, 'Kristin')}>
                            <img className='headshot' src={Kristin} style={{ filter: 'grayscale(100%)' }} alt='' ></img>
                            <div className="employeeText">
                                <h2 className="Name">Kristin Barbato<span style={{ color: 'rgb(11, 108, 254)' }}>.</span></h2>
                                <h2 className="Title">Founder and CEO</h2>
                            </div>
                        </div>
                        <div className="employeeContainer" onClick={() => this.openBio(Meaghan, 'Meaghan')}>
                            <img className='headshot' src={Meaghan} style={{ filter: 'grayscale(100%)' }} alt='' ></img>
                            <div className="employeeText">
                                <h2 className="Name">Meaghan Yorro<span style={{ color: 'rgb(11, 108, 254)' }}>.</span></h2>
                                <h2 className="Title">Director of Commercial Strategy</h2>
                            </div>
                        </div>
                        <div className="employeeContainer" onClick={() => this.openBio(Max, 'Max')}>
                            <img className='headshot' src={Max} style={{ filter: 'grayscale(100%)' }} alt='' ></img>
                            <div className="employeeText">
                                <h2 className="Name">Max McCafferty<span style={{ color: 'rgb(11, 108, 254)' }}>.</span></h2>
                                <h2 className="Title">Chief Operating Officer</h2>
                            </div>
                        </div>
                        <div className="employeeContainer" onClick={() => this.openBio(Barbara, 'Barbara')}>
                            <img className='headshot' src={Barbara} style={{ filter: 'grayscale(100%)' }} alt='' ></img>
                            <div className="employeeText">
                                <h2 className="Name">Barbara Kates-Garnick<span style={{ color: 'rgb(11, 108, 254)' }}>.</span></h2>
                                <h2 className="Title">Head of Regulatory Strategy</h2>
                            </div>
                        </div>
                        <div className="employeeContainer" onClick={() => this.openBio(Zach, 'Zach')}>
                            <img className='headshot' src={Zach} style={{ filter: 'grayscale(100%)' }} alt='' ></img>
                            <div className="employeeText">
                                <h2 className="Name">Zach Sussman<span style={{ color: 'rgb(11, 108, 254)' }}>.</span></h2>
                                <h2 className="Title">Director of Built Environment</h2>
                            </div>
                        </div>
                        <div className="employeeContainer" onClick={() => this.openBio(BarbaraL, 'BarbaraL')}>
                            <img className='headshot' src={BarbaraL} style={{ filter: 'grayscale(100%)' }} alt='' ></img>
                            <div className="employeeText">
                                <h2 className="Name">Barbara Lantz<span style={{ color: 'rgb(11, 108, 254)' }}>.</span></h2>
                                <h2 className="Title">Communications Manager</h2>
                            </div>
                        </div>
                        <div className="employeeContainer" onClick={() => this.openBio(MaxMel, 'MaxMel')}>
                            <img className='headshot' src={MaxMel} style={{ filter: 'grayscale(100%)' }} alt='' ></img>
                            <div className="employeeText">
                                <h2 className="Name">Max Melnick<span style={{ color: 'rgb(11, 108, 254)' }}>.</span></h2>
                                <h2 className="Title">Senior Associate</h2>
                            </div>
                        </div>
                        <div className="employeeContainer" onClick={() => this.openBio(Meg, 'Meg')}>
                            <img className='headshot' src={Meg} style={{ filter: 'grayscale(100%)' }} alt='' ></img>
                            <div className="employeeText">
                                <h2 className="Name">Meg McGriff<span style={{ color: 'rgb(11, 108, 254)' }}>.</span></h2>
                                <h2 className="Title">Contributing Author</h2>
                            </div>
                        </div>
                        <h2 className="TeamSectionTitle" style={{ marginTop: '100px', marginBottom: '70px', marginLeft: '15px'}}>Advisors<span style={{ color: 'rgb(11, 108, 254)' }}>.</span></h2>
                    </div>
                        <div className='advisorsContainer'>
                            <div className='advisor'>
                                <img className="advisorHeadshot" src={DanW} alt='' />
                                <div className='advisorText'>
                                    <h2 className="advisorName">Dan Weeden<span style={{ color: 'rgb(11, 108, 254)' }}>.</span></h2>
                                    <h2 className='advisorship'>PROJECT DEVELOPMENT ADVISOR</h2>
                                    <h2 className="advisorTitle">President & Founder at Leadership Catalytics</h2>
                                    <a href='https://www.linkedin.com/in/dan-weeden-7117a33/' target="_blank" rel="noopener noreferrer"><img className='advisorLIicon' src={LIicon} alt=''/></a>
                                </div>
                            </div>
                            <div className='advisor'>
                                <img className="advisorHeadshot" src={micheleD} alt='' />
                                <div className='advisorText'>
                                    <h2 className="advisorName">Michele Demers<span style={{ color: 'rgb(11, 108, 254)' }}>.</span></h2>
                                    <h2 className='advisorship'>CLIMATE IMPACT ADVISOR</h2>
                                    <h2 className="advisorTitle">Founder & CEO at Boundless Impact Investing</h2>
                                    <a href='https://www.linkedin.com/in/michele-demers-bb805119/' target="_blank" rel="noopener noreferrer"><img className='advisorLIicon' src={LIicon} alt='' /></a>
                                </div>
                            </div>
                            <div className='advisor'>
                                <img className="advisorHeadshot" src={shesiX} alt='' />
                                <div className='advisorText'>
                                    <h2 className="advisorName">Shesi Xie<span style={{ color: 'rgb(11, 108, 254)' }}>.</span></h2>
                                    <h2 className='advisorship'>PUBLIC PRIVATE PARTNERSHIP ADVISOR</h2>
                                    <h2 className="advisorTitle">Owner & Managing Partner, SXM Strategies</h2>
                                    <a href='https://www.linkedin.com/in/shesi-xie-68487b6/' target="_blank" rel="noopener noreferrer"><img className='advisorLIicon' src={LIicon} alt=''/></a>
                                </div>
                            </div>
                            <div className='advisor'>
                                <img className="advisorHeadshot" src={carlaT} alt='' />
                                <div className='advisorText'>
                                    <h2 className="advisorName">Carla Tully<span style={{ color: 'rgb(11, 108, 254)' }}>.</span></h2>
                                    <h2 className='advisorship'>RENEWABLES AND GENERATION ADVISOR</h2>
                                    <h2 className="advisorTitle">Co-Founder & CEO Earthrise Energy</h2>
                                    <a href='https://www.linkedin.com/in/carla-tully-a223391/' target="_blank" rel="noopener noreferrer"><img className='advisorLIicon' src={LIicon} alt='' /></a>
                                </div>
                            </div>
                            <div className='advisor'>
                                <img className="advisorHeadshot" src={kentG} alt='' />
                                <div className='advisorText'>
                                    <h2 className="advisorName">Dr. Kent Griffith<span style={{ color: 'rgb(11, 108, 254)' }}>.</span></h2>
                                    <h2 className='advisorship'>BATTERY STORAGE CHEMISTRY ADVISOR</h2>
                                    <h2 className="advisorTitle">PHD & multiple patent holder at Northwestern University</h2>
                                    <a href='https://www.linkedin.com/in/kent-j-griffith/' target="_blank" rel="noopener noreferrer"><img className='advisorLIicon' src={LIicon} alt=''/></a>
                                </div>
                            </div>
                            <div className='advisor'>
                                <img className="advisorHeadshot" src={kenJ} alt='' />
                                <div className='advisorText'>
                                    <h2 className="advisorName">Kenneth Jack<span style={{ color: 'rgb(11, 108, 254)' }}>.</span></h2>
                                    <h2 className='advisorship'>MOBILTY AND FLEET ADVISOR</h2>
                                    <h2 className="advisorTitle">VP Fleet Operations, Verizon & former General Manager Transportation Operations, Con Edison</h2>
                                    <a href='https://www.linkedin.com/in/kenneth-jack-0659a710/' target="_blank" rel="noopener noreferrer"><img className='advisorLIicon' src={LIicon} alt='' /></a>
                                </div>
                            </div>
                            <div className='advisor'>
                                <img className="advisorHeadshot" src={arthurK} alt='' />
                                <div className='advisorText'>
                                    <h2 className="advisorName">Arthur Kressner<span style={{ color: 'rgb(11, 108, 254)' }}>.</span></h2>
                                    <h2 className='advisorship'>GRID TECHNOLOGY ADVISOR</h2>
                                    <h2 className="advisorTitle">US Dept of Energy Advisory Board and former Con Edison Director of R&D</h2>
                                    <a href='https://www.linkedin.com/in/arthur-kressner-9b62359/' target="_blank" rel="noopener noreferrer"><img className='advisorLIicon' src={LIicon} alt=''/></a>
                                </div>
                            </div>
                            <div className='advisor'>
                                <img className="advisorHeadshot" src={RobertG} alt='' />
                                <div className='advisorText'>
                                    <h2 className="advisorName">Robert O. Gurman<span style={{ color: 'rgb(11, 108, 254)' }}>.</span></h2>
                                    <h2 className='advisorship'>TRANSPORTATION ELECTRIFICATION ADVISOR</h2>
                                    <h2 className="advisorTitle">Managing Consultant, Proenergy Consulting LLC</h2>
                                    <a href='https://www.linkedin.com/in/robert-o-gurman-57a115/' target="_blank" rel="noopener noreferrer"><img className='advisorLIicon' src={LIicon} alt='' /></a>
                                </div>                            
                            </div>
                    </div>
                </Element>
            </div>
        )
    }
}