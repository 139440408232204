import React from "react"
import "./Stylesheets/Solutions.css"
import blueArrowIcon from "./Assets/Icons/arrowiconblue.png"
import whiteArrowIcon from "./Assets/Icons/arrowicon.png"
import solutionsIcon from "./Assets/Icons/solutionsIcon.png"
import cleantechIcon from "./Assets/Icons/cleantechicon.png"
import investorIcon from "./Assets/Icons/investoricon.png"
import corpIcon from "./Assets/Icons/corpicon.png"
import { Link, Element, animateScroll as scroller } from 'react-scroll'
import { ScrollToTopOnMount } from './Scroll'


export class Solutions extends React.Component {

    scrollTo() {
        scroller.scrollTo('scroll-to-element', {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart'
        })
    }

    render() {
        return (
            <div>
                <ScrollToTopOnMount/>
                <div className="Solutionsimage">
                    <div className="SolutionsimageMask">
                        <div className="SolutionstitleText">
                            <p className="SolutionsmainTitle">Our <span style={{ color: 'rgb(11, 108, 254)' }}>Solutions.</span></p>
                            <p className="Solutionssubtitle">We help our clients achieve their goals in the clean energy space.</p>
                            <a href='/#/contact/'>
                                <div className='SolutionsmainContactLink'>
                                    <p className='SolutionscontactLinkText'>Contact Us</p>
                                    <img className="SolutionsblueArrowIcon" src={blueArrowIcon} alt='' />
                                </div>
                            </a>
                            <Link activeClass="active" className="scroll" to="content" spy={true} smooth={true} duration={500} ><img className="SolutionswhiteArrowIcon" src={whiteArrowIcon} alt='' /></Link>
                        </div>
                    </div>
                </div>
                <Element name="content" className="element" >
                    <div className='homeContentContainer'>
                        <div className="homeTextcontainer">
                            <h2 className="HomecontactTagline">Solutions<span style={{ color: 'rgb(11, 108, 254)' }}>.</span></h2>
                            <h2 className="HomeText">Build Edison helps our clients achieve their commercialization goals in the clean energy space. We work with everything from cleantech companies, investors, strategic partners, government innovation programs and utilities from all over the world.  In bringing together our network, expertise, and capital we provide focused solutions to establish and ensure the continued success of our clients.</h2>
                            <a href='/#/contact/'><div className='HomemainContactLink' style={{ height: '40px' }}>
                                <p className='HomecontactLinkText' style={{ color: 'black', marginTop: '8px' }}>Contact Us</p>
                                <img className="HomeblueArrowIcon" src={blueArrowIcon} alt='' style={{ marginTop: '10px', marginLeft: '88px' }} />
                            </div></a>
                        </div>
                        <div className='homeIconContainer'>
                            <img className='HomeIcon' style={{ marginRight: '40px', marginTop: '165px' }} src={solutionsIcon} alt='' />
                        </div>
                    </div>
                    <div className='homeContentContainer'>
                        <div className="homeTextcontainerRight">
                            <h2 className="HomecontactTaglineRight">Cleantech Startups<span style={{ color: 'rgb(11, 108, 254)' }}>.</span></h2>
                            <h2 className="HomeTextRight">The core of Build Edison's work is with cleantech startups and innovative technologies across utilities, the built environment, renewables, and mobility. We have years of experience guiding new companies from revolutionary technologies into revenue producing businesses.  Whether the solution is capital, consulting, or industry access, Build Edison can help. Get in touch today.</h2>
                            <a href='/#/contact/' style={{ height: '50px' }}><div className='HomemainContactLinkRight' style={{marginRight: '105px'}}>
                                <p className='HomecontactLinkText' style={{ color: 'black', marginTop: '8px' }}>Contact Us</p>
                                <img className="HomeblueArrowIcon" src={blueArrowIcon} alt='' style={{ marginTop: '10px', marginLeft: '88px' }} />
                            </div></a>
                        </div>
                        <div className='homeIconContainerRight'>
                            <img className='HomeIconRight' style={{ marginLeft: '52.5px', marginTop: '150px' }} src={cleantechIcon} alt='' />
                        </div>
                        <div style={{ clear: 'both' }}></div>
                    </div>
                    <div className='homeContentContainer'>
                        <div className="homeTextcontainer">
                            <h2 className="HomecontactTagline">Investors<span style={{ color: 'rgb(11, 108, 254)' }}>.</span></h2>
                            <h2 className="HomeText">Build Edison actively indentifies new clean technologies with exceptional potential and works to grow them into successful companies to realize returns. This not only generates value for investors but provides access and insight into unique cleantech investment opportunities.  Learn more about Build Edison's cleantech investment fund and advisory services today.</h2>
                            <a href='/#/contact/'><div className='HomemainContactLink' style={{ height: '40px' }}>
                                <p className='HomecontactLinkText' style={{ color: 'black', marginTop: '8px' }}>Contact Us</p>
                                <img className="HomeblueArrowIcon" src={blueArrowIcon} alt='' style={{ marginTop: '10px', marginLeft: '88px' }} />
                            </div></a>
                        </div>
                        <div className='homeIconContainer'>
                            <img className='HomeIcon' style={{ marginRight: '46.25px', marginTop: '165px' }} src={investorIcon} alt='' />
                        </div>
                    </div>
                    <div className='homeContentContainer'>
                        <div className="homeTextcontainerRight">
                            <h2 className="HomecontactTaglineRight">Corporations and Government<span style={{ color: 'rgb(11, 108, 254)' }}>.</span></h2>
                            <h2 className="HomeTextRight">Build Edison has decades of experience in the industry, and actively consults with corporate partners to capitalize on the opportunity within the energy space.  Additionally, our work with cleantechs means our clients have access to unique clean energy technologies and investment opportunities.  Find out how Build Edison can 
        help your firm today.</h2>
                            <a href='/#/contact/' style={{ height: '50px' }}><div className='HomemainContactLinkRight' style={{ marginRight: '105px' }}>
                                <p className='HomecontactLinkText' style={{ color: 'black', marginTop: '8px' }}>Contact Us</p>
                                <img className="HomeblueArrowIcon" src={blueArrowIcon} alt='' style={{ marginTop: '10px', marginLeft: '88px' }} />
                            </div></a>
                        </div>
                        <div className='homeIconContainerRight'>
                            <img className='HomeIconRight' style={{ marginLeft: '40px', marginTop: '150px' }} src={corpIcon} alt='' />
                        </div>
                        <div style={{ clear: 'both' }}></div>
                    </div>
                    <div style={{width: '100%', height: '70px'}}></div>
                    <div className='SolutionslowerContactButtonContainer'>
                        <a href='/#/contact/' style={{ textDecoration: 'none' }}>
                            <p className="SolutionslowerContactButton" style={{ textDecoration: 'none' }}>Contact Us Now</p>
                        </a>
                    </div>
                </Element>
            </div>
        )
    }
}