import React from "react";
import "./Stylesheets/Home.css"
import blueArrowIcon from "./Assets/Icons/arrowiconblue.png"
import whiteArrowIcon from "./Assets/Icons/arrowicon.png"
import NYIcon from "./Assets/Icons/NYIcon.png"
import NetworkIcon from "./Assets/Icons/NetworkIcon.png"
import StartupIcon from "./Assets/Icons/StartupIcon.png"
import close from "./Assets/Icons/close.png"
import Aclima from "./Assets/Logos/Aclima.jpg"
import AlliedM from "./Assets/Logos/AlliedM.jpg"
import Cornell from "./Assets/Logos/Cornell.jpg"
import Cypress from "./Assets/Logos/Cypress.jpg"
import DKEnergy from "./Assets/Logos/DKEnergy.jpg"
import NYUL from "./Assets/Logos/NYUL.jpg"
import Illumra from "./Assets/Logos/Illumra.jpg"
import Bloom from "./Assets/Logos/Bloom.jpg"
import Selex from "./Assets/Logos/Selex.jpg"
import EnviroP from "./Assets/Logos/EnviroP.jpg"
import GCap from "./Assets/Logos/GCap.jpg"
import LogCheck from "./Assets/Logos/LogCheck.jpg"
import Micatu from "./Assets/Logos/Micatu.jpg"
import Murata from "./Assets/Logos/Murata.jpg"
import NYSERDA from "./Assets/Logos/NYSERDA.jpg"
import Pivot from "./Assets/Logos/Pivot.jpg"
import SwitchedS from "./Assets/Logos/SwitchedS.jpg"
import BII from "./Assets/Logos/BII.jpg"
import Columbia from "./Assets/Logos/Columbia.jpg"
import Kilroy from "./Assets/Logos/Kilroy.jpg"
import NYU from "./Assets/Logos/NYU.jpg"
import ConEd from "./Assets/Logos/ConEd.jpg"
import EdisonE from "./Assets/Logos/EdisonE.jpg"
import FPL from "./Assets/Logos/FPL.jpg"
import NG from "./Assets/Logos/NG.jpg"
import NYC from "./Assets/Logos/NYC.jpg"
import NYPA from "./Assets/Logos/NYPA.jpg"
import OR from "./Assets/Logos/OR.jpg"
import RL from "./Assets/Logos/RL.jpg"
import Tesla from "./Assets/Logos/Tesla.jpg"
import Thinkeco from "./Assets/Logos/Thinkeco.jpg"
import UI from "./Assets/Logos/UI.jpg"
import CH from "./Assets/Logos/CH.jpg"
import PAU from "./Assets/Logos/PAU.jpg"
import BOSS from "./Assets/Logos/Boss.png"
import PowerHV from "./Assets/Logos/PowerHV.png"
import Wex from "./Assets/Logos/Wex.png"
import RMI from "./Assets/Logos/RMI.jpg"
import EThree from "./Assets/Logos/E3.jpg"
import NBI from "./Assets/Logos/NBI.png"
import Hersha from "./Assets/Logos/Hersha.png"
import Enverid from "./Assets/Logos/enverid.png"
import Hygge from "./Assets/Logos/hygge.png"
import WM from "./Assets/Logos/WM.jpg"
import LBL from "./Assets/Logos/LBL.jpg"
import NL from "./Assets/Logos/NL.png"
import HEVO from "./Assets/Logos/HEVO.png"
import BP from "./Assets/Logos/BP.png"
import Dynamo from "./Assets/Logos/Dynamo.png"
import Dollaride from "./Assets/Logos/Dollaride.png"
import Stringbean from "./Assets/Logos/Stringbean.png"
import BGE from "./Assets/Logos/BGE.jpg"
import RAP from "./Assets/Logos/RAP.jpg"
import BlocPower from "./Assets/Logos/Blocpower.png"
import { Link, Element, animateScroll as scroller } from 'react-scroll'
import { ScrollToTopOnMount } from './Scroll'

const clientInfo = {
    PowerHV: {
        name: "Power HV"
        , type: "Advisory Client"
        , date: "2020"
        , logo: PowerHV
        , category: "Smart Grid Technology"
        , location: "St. John’s NL, Canada"
        , role: "Utility Sales Channel Development Advisor"
        , customers: "Utilities"
        , overview: "With our patented energy efficient bushing solution using magnetic decoupling, we increase efficiency of bushings by 80%, while keeping the same quality and life expectancy of other bushings availible today. This bushing is PCB free. With our advanced manufacturing processes, we are able to deliver these standard bushings in only 4 weeks, 3 times shorter than the industry average. This bushing is PCB free. Our Monitoring Sensors allow our customers to predict when their bushings are going to fail, preventing bushing and transformer failures therefore saving money and keeping the grid more stable."
        , website: "power-hv.com"
    },
    Wex: {
        name: "WexEnergy"
        , type: "Advisory Client"
        , date: "2020"
        , logo: Wex
        , category: "Building envelope efficiency hardware"
        , location: "Rochester, NY"
        , role: "Marketing Strategy & Sales Channel Development Advisor"
        , customers: "Commercial/industrial, government, utilities, education"
        , overview: "If you’ve ever gotten an estimate for replacement windows, you know that the project – particularly in larger buildings – can be really expensive. And the payback takes too long to justify the cost. Yet, poorly insulated windows can account for 10% to 25% of your total heating/cooling charges because your HVAC systems have to work that much harder to maintain consistent, comfortable temperatures. WexEnergy’s patented WindowSkin™ is the easy solution to this problem – a solution that delivers a much higher return on investment than replacement windows. Simple to mount, these lightweight custom-fit thermal barriers look great and work great too. Just press the WindowSkin on to your existing windows to add an extra layer of efficiency. They’ll keep the heat in and the cold out all winter long, and in summer, they’ll keep the heat out while your AC system keeps your tenants cool. You’ll save a surprising amount of money. And if sustainability is a part of your brand story, this energy efficient, environmentally friendly solution will add to that as well."
        , website: "wexenergy.com"
    },
    Boss: {
        name: "BOSS Controls"
        , type: "Advisory Client"
        , date: "2019"
        , logo: BOSS
        , category: "Efficiency hardware and software solutions"
        , location: "Pittsburg, PA"
        , role: "Product Strategy & Utility Sales Channel Development Advisor"
        , customers: "Commercial/industrial, government, utilities, education"
        , overview: "The BOSS energy management system is an easy to install, plug-and-play solution that allows you to automate and control all plug loads in you educational facility from one interface. BOSS is the first cloud-based commercial solution designed specifically for the needs of small-to-mid-size organizations. From commercial to education and government facilities, BOSS is helping businesses run leaner and greener."
        , website: "bosscontrols.com"
    },
    Aclima: {
        name: "Aclima"
        , type: "Advisory Client"
        , date: "2018"
        , logo: Aclima
        , category: "Precision air monitoring and data analytics"
        , location: "San Francisco, CA"
        , role: "Product Strategy & Utility Pilot Development advisor"
        , customers: "Utilities, municipalities, industrials, and insurance companies"
        , overview: "Aclima delivers hyperlocal air pollution and climate emissions intelligence at unprecedented block-by-block resolution. With our platform, governments, companies, researchers and the public can see and track pollution hotspots and impacts over time — from traffic congestion to gas leaks and wildfire. This new capability empowers informed action to reduce emissions and exposure, at both the local and global level. Based on years of R&D, Aclima’s air quality mapping and analysis platform combines our breakthrough mobile and stationary sensing technology, climate science and machine learning to provide next-generation diagnostics of critical air pollutants — from CO2 and methane to particulate matter. With this new layer of Environmental Intelligence, Aclima makes the invisible visible, charting a new course for improving human and planetary health."
        , website: "www.aclima.io"
    },
    AlliedM: {
        name: "Allied Microbiota"
        , type: "Advisory Client"
        , date: "2018"
        , logo: AlliedM
        , category: "Waste to energy conversion through bioproduct microbes"
        , location: "Brooklyn, NY"
        , role: "Marketing Strategy & Project Advisor"
        , customers: "Commercial industrial facilities, developers, and governments"
        , overview: "Founded in the labs of Columbia University, Allied Microbiota develops bacteria, other microbes and their enzymes to clean-up environmental contamination, and prevent future problems by developing more sustainable bio-chemicals. Our disruptive augmentation cultures have shown ground breaking results to treat PCBs, Dioxins and chlorinated compounds in soil and sediments."
        , website: "www.alliedmicrobiota.com"
    },
    Cypress: {
        name: "Cypress Envirosystems"
        , type: "Advisory Client"
        , date: "2018"
        , logo: Cypress
        , category: "Non-invasive retrofit technologies"
        , location: "San Jose, CA"
        , role: "Business Development & Strategy Advisor"
        , customers: "Commercial / industrial facilities"
        , overview: "Cypress Envirosystems provides non-invasive Internet of Things (IoT) retrofit solutions for existing commercial buildings and industrial facilities. Our IoT solutions digitize and connect pneumatic thermostats, lights, steam traps, freezers, analog needle gauges and transducers. Customers are able to cost-effectively integrate legacy equipment to gain visibility, control, and diagnostics to improve the performance of their facilities."
        , website: "www.cypressenvirosystems.com"
    },
    DKEnergy: {
        name: "DK Energy"
        , type: "Advisory Client"
        , date: "2018"
        , logo: DKEnergy
        , category: "Energy services company"
        , location: "Washington, DC"
        , role: "Target acquisition analysis"
        , customers: "Efficiency and DERs for buildings"
        , overview: "DK Energy US, a subsidiary of the EDF group, is tasked with developing the United States business of Dalkia SA, one of the leading providers of energy services in France. DK Energy US offers its clients tailor-made solutions scaled to fit each building, city, municipality, region and industrial site.   We provide our clients with expertise covering the entire energy value chain, from energy supply to optimization of their energy consumption, as well as operation and maintenance of installations.  DK Energy US’s solutions can be paired with energy efficiency commitments and long-term performance guarantees as necessary."
        , website: "www.groomenergy.com/dk-energy-us-llc/"
    },
    Enverid: {
        name: "enVerid"
        , type: "Advisory Client"
        , date: "2021"
        , logo: Enverid
        , category: "Energy Efficient HVAC Technology"
        , location: "Westwood, MA"
        , role: "Sales Channel Development"
        , customers: "Real estate owners and operators"
        , overview: "enVerid is committed to improving energy efficiency and indoor air quality in buildings worldwide through its innovative, award-winning HVAC Load Reduction® (HLR®) solutions. HLR technology enables immediate capital cost savings on new HVAC systems and provides up to 40% energy savings and superior indoor air quality. Deployed in nearly 10 million sqf of commercial, academic, and government buildings, enVerid’s HLR technology is ASHRAE-compliant, LEED-compliant, and eligible for utility rebates."
        , website: "https://enverid.com/"
    },
    EnviroP: {
        name: "EnviroPower"
        , type: "Advisory Client"
        , date: "2018"
        , logo: EnviroP
        , category: "High efficiency heating and backup power generation"
        , location: "Hartford, CT"
        , role: "Marketing Strategy & Project Advisor"
        , customers: "Public and private sector energy consumers and operators"
        , overview: "Enviro Power revolutionizes the production of heat, making it effective, efficient and smart. Our heating technology, the SmartWatt Boiler, is the next generation heating technology. Producing high efficiency heat while generating cleaner and inexpensive electricity, the SmartWatt Boiler, is designed to generate significant savings on operating-cost for home owners, landlords and grid operators and reduce the carbon footprint for millions of buildings. We save billions of dollars in annual operating cost and reduce CO2 emission annually by over 105 million tons."
        , website: "www.enviropowertec.com"
    },
    GCap: {
        name: "Generate Capital"
        , type: "Advisory Client"
        , date: "2018"
        , logo: GCap
        , category: "Capital partnership for clean energy infrastructure"
        , location: "San Francisco, CA"
        , role: "NYC Schools Solar Project advisor"
        , customers: "Public and private sector energy consumers and operators"
        , overview: "After decades of collective experience financing billions of dollars of sustainable infrastructure, Generate Capital has created the Infrastructure-as-a-Service™ model. Unlike traditional capital sources, Generate Capital has the people, systems, and flexibility that make us the leading capital partner for technology manufacturers, project developers, and system integrators driving the Resource Revolution."
        , website: "www.generatecapital.com"
    },
    LogCheck: {
        name: "LogCheck"
        , type: "Advisory Client"
        , date: "2018"
        , logo: LogCheck
        , category: "Automating building maintenance through mobile apps"
        , location: "New York, NY"
        , role: "Marketing Strategy & Project Advisor"
        , customers: "Utilities and commercial / industrial facilities"
        , overview: "The idea for LogCheck was born in the boiler rooms of New York City. It was built for engineers, by engineers, who saw the need for a simple tool that improves the training and augments the value of the people responsible for keeping our buildings safe, comfortable, and efficient. With all the focus on automation and monitoring systems in modern buildings, it’s easy to forget about the human operators, diligently working behind the scenes fixing issues before you’re even aware of them. We haven’t forgotten about them, because we know first hand that automation systems are far from perfect and the importance of skilled operators doing real work isn’t going away anytime soon. LogCheck is a simple mobile tool to help building maintenance staff automate their routine checks, tasks and reporting in the field. Staff log their activities, management checks them as needed, everyone’s kept on the same page without the paperwork."
        , website: "www.logcheck.com"
    },
    Micatu: {
        name: "Micatu"
        , type: "Advisory Client"
        , date: "2019"
        , logo: Micatu
        , category: "Smart grid distribution sensor technology and systems"
        , location: "Horseheads, NY"
        , role: "Utility demonstration project "
        , customers: "Utilities and industrials"
        , overview: "The Micatu sensor platform is a highly accurate, optical-based modular solution for condition monitoring of power distribution systems, power transformers and power generation equipment. The Micatu optical solution platform is an all optical, configurable and modular condition monitoring platform that can support four types of sensors; voltage, current, vibration, and temperature each with a common connection module that connects to the M410 modular sensor processor."
        , website: "www.micatu.com"
    },
    Murata: {
        name: "Murata"
        , type: "Advisory Client"
        , date: "2018"
        , logo: Murata
        , category: "Technology and battery manufacturing"
        , location: "Tokyo, Japan"
        , role: "Battery storage product and market channel advisor"
        , customers: "Buildings, cars, and solar plus storage companies"
        , overview: "Murata has a variety of electronic products ranging from communication and wireless modules to power supplies. Murata's batteries are used to power a variety of electronic equipment, smartphones, electric power tools as well as energy storage systems for households and businesses. Murata's energy storage modules are built from olivine-type lithium ion iron phosphate rechargeable batteries, which are known for their longevity, safety, and fast-charging capabilities."
        , website: "www.murata.com"
    },
    NYSERDA: {
        name: "NYSERDA"
        , type: "Advisory Client"
        , date: "2018"
        , logo: NYSERDA
        , category: "Government clean energy investment"
        , location: "Albany, NY"
        , role: "Innovation Advisor for Smart Grid"
        , customers: "Cleantech companies and renewables"
        , overview: "The New York State Energy Research and Development Authority, known as NYSERDA, promotes energy efficiency and the use of renewable energy sources. These efforts are key to developing a less polluting and more reliable and affordable energy system for all New Yorkers. Collectively, NYSERDA’s efforts aim to reduce greenhouse gas emissions, accelerate economic growth, and reduce customer energy bills. NYSERDA works with stakeholders throughout New York including residents, business owners, developers, community leaders, local government officials, university researchers, utility representatives, investors, and entrepreneurs. NYSERDA partners with them to develop, invest, and foster the conditions that: attract the private sector capital investment needed expand New York’s clean energy economy, overcome barriers to using clean energy at a large-scale in New York, and enable New York’s communities and residents to benefit from energy efficiency and renewable energy."
        , website: "www.nyserda.ny.gov"
    },
    Pivot: {
        name: "Pivot Energy Services"
        , type: "Advisory Client"
        , date: "2018"
        , logo: Pivot
        , category: "Investment grade analytics for insurance backed efficiency projects"
        , location: "Stockholm, Sweden"
        , role: "Product strategy & demonstration pilot development"
        , customers: "Commercial / industrial facilities, developers, and insurance companies"
        , overview: "Pivot’s core software combines building energy performance, machine learning and risk analytics, to create a standardized, smart integration layer for the discovery, insurance and financing of energy efficiency and renewables projects in commercial buildings. Pivot standardizes the quantification of technical performance risk embedded within an energy efficiency and renewables retrofit.  Our risk signal is consumed by an insurance company and project finance providers forming an interface and integration layer for capital thereby removing the need to assess projects on an individual basis."
        , website: "www.pivotenergy.com"
    },
    SwitchedS: {
        name: "Switched Source"
        , type: "Advisory Client"
        , date: "2018"
        , logo: SwitchedS
        , category: "Next generation low cost power flow control"
        , location: "Detroit, MI"
        , role: "Product Strategy & Utility Pilot Development advisor"
        , customers: "Utilities and industrials"
        , overview: "Switched Source is providing power-electronics solutions to increase reliability, efficiency, and renewable energy hosting capacity, on legacy grid infrastructure and in distributed energy system applications. Switched Source’s flagship device, the Tie Controller, unlocks superior grid management capabilities, acting as a controllable valve to “push and pull” specified amounts of both real and reactive power, in two directions.  As a result, neighboring power lines can be operated as a networked platform, while providing all energy consumers with two sources of power."
        , website: "www.switchedsource.com"
    },
    BII: {
        name: "Boundless Impact Investing"
        , type: "Corporate Partner"
        , date: "2019"
        , logo: BII
        , category: "Clean technology vetting and impact metrics"
        , location: "New York, NY"
        , role: ""
        , customers: ""
        , overview: "Boundless Impact Investing is the premier market intelligence platform for impact investors. Driven by superior research from independent sector experts, Boundless offers investors in-depth analysis of critical sectors of impact investing, financial diligence on the best investment opportunities, and a private social network to engage experts and share insights with peer investors."
        , website: "www.boundlessimpact.net"
    },
    Columbia: {
        name: "Columbia University"
        , type: "Corporate Partner"
        , date: "2015"
        , logo: Columbia
        , category: "Energy Management for Public & Private Sector Portfolios, SIPA"
        , location: "New York, NY"
        , role: ""
        , customers: ""
        , overview: "Columbia University's School of International Affairs was founded in 1946 in the aftermath of World War II. Emphasizing practical training, the School's mission was to foster understanding of geographic regions of vital interest and to prepare diplomats, officials, and other professionals to meet the complex needs of the postwar world."
        , website: "sipa.columbia.edu"
    },
    Cornell: {
        name: "Cornell University"
        , type: "Corporate Partner"
        , date: "2018"
        , logo: Cornell
        , category: "Energy Research Center team"
        , location: "Ithaca, NY"
        , role: ""
        , customers: ""
        , overview: "The mission of the Cornell Energy Systems Institute (CESI) is to \"make smart energy systems with low carbon footprint the norm through innovations in materials, technology, and systems design. In pursuit of this mission, the institute intends to take on an ambitious agenda spanning discovery research to technology translation. The goal is to catalyze the frontier research on materials, devices, data analytics, and intelligent systems architectures required to lower cost, improve performance, and reduce carbon footprint of energy systems. The institute also serves as a hub for subject matter experts, programs, and multi-user facilities designed to translate energy-focused research discoveries to prototypes and prototypes to commercial practice. "
        , website: "energy.cornell.edu"
    },
    Hersha: {
        name: "Hersha Hotels and Resorts"
        , type: "Corporate Partner"
        , date: "2020"
        , logo: Hersha
        , category: "Real estate facility partner"
        , location: "Philadelphia, PA"
        , role: ""
        , customers: ""
        , overview: "Hersha Hospitality Trust (NYSE: HT) is a self-advised real estate investment trust in the hospitality sector, which owns and operates high quality upscale, luxury and lifestyle transient hotels in urban gateway markets and coastal destinations. The Company’s 48 hotels totaling 7,644 rooms are located in New York, Washington, DC, Boston, Philadelphia, Miami and select markets on the West Coast."
        , website: "www.hersha.com"
    },
    Kilroy: {
        name: "Kilroy Realty"
        , type: "Corporate Partner"
        , date: "2018"
        , logo: Kilroy
        , category: "Real estate facility partner"
        , location: "Los Angeles, CA"
        , role: ""
        , customers: ""
        , overview: "Kilroy is committed to mastering all aspects of our industry, including property and land acquisition, financing and property development, construction management, leasing and property operations, and long-term strategic portfolio development. We share a common interest with our clients for work environments that optimize resources and minimize the impact on the environment. That’s why we have become a leading proponent of sustainable design, development and property operations. We build all of our development projects at a minimum of Gold Certification and integrate sustainable practices at every opportunity."
        , website: "www.kilroyrealty.com"
    },
    NYU: {
        name: "New York University"
        , type: "Corporate Partner"
        , date: "2014"
        , logo: NYU
        , category: "Electric Utilities 101, CGA"
        , location: "New York, NY"
        , role: ""
        , customers: ""
        , overview: "The NYUSPS Center for Global Affairs (CGA) prepares the next generation of professionals to anticipate and address the most critical global challenges and to create a more just, equal, and sustainable world. CGA's students, alumni, and faculty practitioners come from around the globe and form a network that extends into the private, public, and civil society sectors, connecting research, policy, and practice."
        , website: "www.sps.nyu.edu"
    },
    Bloom: {
        name: "Bloom Energy"
        , type: "Project Partner"
        , date: "2013"
        , logo: Bloom
        , category: "NYC City Hall fuel cell installation"
        , location: "San Jose, CA"
        , role: ""
        , customers: ""
        , overview: "Bloom Energy delivers always-on, reliable, resilient, sustainable and cost effective on-site electricity for organizations globally. Our customizable Bloom Energy Server platform enables you to personalize your energy—allowing you to grow your business without disruption, meet sustainability goals and reduce your carbon footprint. Bloom Energy Servers are the foundation for next generation energy solutions. Whether it’s a datacenter that requires mission critical power, a retail chain looking to reduce demand charges or a microgrid powering a campus or city—we are able to provide lower and predictable energy costs and enhanced reliability—all while reducing carbon emissions."
        , website: "www.bloomenergy.com"
    },
    CH: {
        name: "Central Hudson"
        , type: "Project Partner"
        , date: "2019"
        , logo: CH
        , category: "Future Grid Utility Challenge"
        , location: "Poughkeepsie, NY"
        , role: ""
        , customers: ""
        , overview: "Central Hudson's mission is to deliver electricity and natural gas to an expanding customer base in a safe, reliable, courteous and affordable manner; to produce growing financial returns for shareholders; to foster a culture that encourages employees to reach their full potential; and to be a good corporate citizen."
        , website: "www.cenhud.com"
    },
    ConEd: {
        name: "Con Edison"
        , type: "Project Partner"
        , date: "2008"
        , logo: ConEd
        , category: "Smart Grid $165M demonstration projects, DER Monitoring & Control Systems demonstration, Customer Billing System management & implementation of new Billing System, and the development and integration of new R&D grid technologies"
        , location: "New York, NY"
        , role: ""
        , customers: ""
        , overview: "Con Edison operates one of the world’s largest energy delivery systems. Founded in 1823 as the New York Gas Light company, our electric, gas, and steam service now provides energy for the 10 million people who live in New York City and Westchester County. We’re constantly looking toward the future and exploring ways to innovate and take advantage of developing technology. But, more than anything, we’re listening to you and working hard to give you cleaner, more efficient energy choices, and more control over when and how you use your power. "
        , website: "www.coned.com"
    },
    EdisonE: {
        name: "Edison Energy"
        , type: "Project Partner"
        , date: "2016"
        , logo: EdisonE
        , category: "Efficiency and DER services, post-acquisitions integration"
        , location: "Newport Beach, California"
        , role: ""
        , customers: ""
        , overview: "Edison Energy partners with market leaders to align energy investments with strategic goals—empowering organizational vision, mitigating risk, and exceeding long-term sustainability and cost saving targets. Energy is the largest unaddressed risk most companies face and can exceed foreign currency, interest rate and other operational risks. We create competitive advantage for market leaders by quantifying this risk and designing the portfolio solution to protect shareholder value threatened by complex energy policies, technological advancements, and new products."
        , website: "www.edisonenergy.com"
    },
    FPL: {
        name: "Florida Power & Light"
        , type: "Project Partner"
        , date: "1996"
        , logo: FPL
        , category: "Substations monitoring and protection systems, power plant environmental design"
        , location: "Juno Beach, FL"
        , role: ""
        , customers: ""
        , overview: "Florida Power & Light Company is the largest energy company in the United States as measured by retail electricity produced and sold, serving more than five million customer accounts or an estimated 10 million+ people across the state of Florida. FPL’s typical 1,000-kWh residential customer bill is approximately 30 percent lower than the latest national average and among the lowest in the U.S. FPL’s service reliability is better than 99.98 percent, and its highly fuel-efficient power plant fleet is one of the cleanest among all electric companies nationwide. The company was recognized in 2018 as one of the most trusted U.S. electric utilities by Market Strategies International for the fifth consecutive year. A leading Florida employer with approximately 8,700 employees, FPL is a subsidiary of Juno Beach, Florida-based NextEra Energy, Inc. (NYSE: NEE), a clean energy company widely recognized for its efforts in sustainability, ethics and diversity, and has been ranked No. 1 in the electric and gas utilities industry in Fortune’s 2019 list of “World’s Most Admired Companies.”"
        , website: "www.fpl.com"
    },
    Illumra: {
        name: "Illumra"
        , type: "Project Partner"
        , date: "2014"
        , logo: Illumra
        , category: "Self-Powered Wireless Lighting Controls"
        , location: "Lindon, UT"
        , role: ""
        , customers: ""
        , overview: "The Illumra self-powered wireless lighting controls consist of switch and relay receivers paired with occupancy sensors that allow lights to turn on automatically upon detection of motion and turn off 15 minutes after no motion is detected. Timing can be set as desired for each space application. This wireless technology allows for easy integration of components into a central controller that uses Bacnet. This enables integration into a BMS System."
        , website: "www.illumra.com"
    },
    NG: {
        name: "National Grid "
        , type: "Project Partner"
        , date: "2019"
        , logo: NG
        , category: "Future Grid Utility Challenge"
        , location: "London, UK"
        , role: ""
        , customers: ""
        , overview: "National Grid is one of the largest investor-owned energy companies in the world—covering Massachusetts, New York, Rhode Island and the UK. We are at the heart of one of the greatest challenges facing our society—delivering clean energy to support our world long into the future. Every day we work with stakeholders to promote the development and implementation of sustainable, innovative and affordable energy solutions. We are proud of the contributions our work and our people make to the prosperity and wellbeing of our customers, communities and investors."
        , website: "www.nationalgridus.com"
    },
    NYC: {
        name: "New York City"
        , type: "Project Partner"
        , date: "2013"
        , logo: NYC
        , category: "ACE, IDEA, ExCEL programs, and energy supply contracts budgets of 1.3B dollars"
        , location: "New York, NY"
        , role: ""
        , customers: ""
        , overview: "DEM launched the Innovative Demonstrations for Energy Adaptability (“IDEA”) Program in 2014 to help meet the City’s emissions reductions goals by engaging vendors to test new or underutilized energy technologies in City buildings. The IDEA Program helps vendors build the use case for their solutions, while also addressing City building system or operational challenges and informing DEM’s cleantech strategy and investments."
        , website: "www1.nyc.gov/site/dcas/agencies/energy-innovation.page"
    },
    NYPA: {
        name: "New York Power Authority"
        , type: "Project Partner"
        , date: "2014"
        , logo: NYPA
        , category: "K-Solar, BuildSmartNY, OMAP NYS customer energy solutions programs"
        , location: "New York, NY"
        , role: ""
        , customers: ""
        , overview: "The K-Solar Program empowers schools to procure affordable solar energy without affecting school district budgets. Governor Cuomo initiated this joint program of NYPA, NYSERDA, the Governor's Energy Office, and the NYS Education Department, and it has become a national model for school solarization. This historic energy program—the largest multi-agency solar buying consortium project in the United States—inspires the next generation of energy leaders and helps schools statewide to save money and lower their carbon footprints. School districts in 59 of New York State’s 62 counties—more than ⅓ of the schools in the state—have joined the program."
        , website: "www.nypa.gov/innovation/programs/k-solar"
    },
    NYUL: {
        name: "NYU Langone"
        , type: "Project Partner"
        , date: "2012"
        , logo: NYUL
        , category: "Design, build, & operations contracts for cogeneration plant"
        , location: "New York, NY"
        , role: ""
        , customers: ""
        , overview: "The Energy Building is the centerpiece of NYU Langone’s plan to become a resilient and reliable medical center and a leader in sustainability by reducing our contribution to global warming as a result of clean and efficient energy use. With a new combined heat and power (CHP) plant, emergency generators, and boilers in the building, the Energy Building affords NYU Langone the capacity to be completely self-sufficient in the event of a utility power interruption, with two different sources of backup power for our critical areas."
        , website: "www.nyulangone.org/our-story/campus-transformation/building-the-energy-building"
    },
    OR: {
        name: "Orange & Rockland Utility"
        , type: "Project Partner"
        , date: "2019"
        , logo: OR
        , category: "Utility - Smart Inverter Integration demonstration"
        , location: "Pearl River, NY"
        , role: ""
        , customers: ""
        , overview: "Located in the northwestern suburbs of New York City, our electric and gas service provides energy for 745,000 people in six counties in New York and northern New Jersey. Founded as the Rockland Light & Power Co. in Nyack, NY in 1899, we merged with Orange and Rockland Electric Company in 1958 and became Orange and Rockland Utilities, Inc. With our subsidiary, Rockland Electric Company, we became wholly owned by Consolidated Edison, Inc. in 1999. We’re constantly looking toward the future and exploring ways to innovate and take advantage of developing technology. But, more than anything, we’re listening to you and working hard to give you cleaner, more efficient energy choices, and more control over when and how you use your power."
        , website: "www.oru.com"
    },
    PAU: {
        name: "Palo Alto Utilities"
        , type: "Project Partner"
        , date: "2018"
        , logo: PAU
        , category: "Pilot methane and air quality monitoring"
        , location: "Palo Alto, CA "
        , role: ""
        , customers: ""
        , overview: "The City of Palo Alto is the only municipality in California that operates a full suite of City-owned utility services. The City of Palo Alto Utilities' (CPAU) history began over one hundred years ago, in 1896, when the water supply system was first installed.  Two years later, the wastewater or sewer collection system came on line in 1898.  In 1900, the municipal electric power system began operation, followed in 1917 by a natural gas distribution system.  Palo Alto is the only city in California to own and operate six essential utility services, including refuse and storm drain (operated out of Public Works). In 1996, Palo Alto ventured into a new endeavor with the construction of its dark fiber loop."
        , website: "www.cityofpaloalto.org"
    },
    RL: {
        name: "Radiator Labs"
        , type: "Project Partner"
        , date: "2014"
        , logo: RL
        , category: "Cozy Radiator Project at FDNY"
        , location: "Brooklyn, NY"
        , role: ""
        , customers: ""
        , overview: "The Cozy is a smart, insulated radiator cover with independently controlled fans to distribute heat to desired spaces. The Cozy enclosure fits over existing radiators and blocks convection, using a thermostatically controlled fan that transfers heat from the radiators to the room when it is called for based on the temperature set point. By allowing heat to be sent to the room only when it is needed based on individual room set points, the Cozy allows for room by-room zone control. Additional controls that provide direct control over boilers and wireless communications may be needed in conjunction with Radiator Labs devices for successful implementation and energy savings."
        , website: "www1.nyc.gov/assets/dcas/downloads/pdf/energy/energyinnovation/Phase1_3.pdf"
    },
    Selex: {
        name: "Selex ES"
        , type: "Project Partner"
        , date: "2014"
        , logo: Selex
        , category: "Predictive Building Control Software"
        , location: "Rome, Italy"
        , role: ""
        , customers: ""
        , overview: "DiBoss is a digital building operating system solution, and provides recommendations for optimizing building operations based on weather forecasting and machine learning. The system functions over existing Building Management Systems, and provides access to a web based dashboard that provides trend logs and forecast information for building operations. Energy savings are expected for all systems with weather dependence and that operate within an existing BMS. The software offers real time commissioning start up and ramp down recommendations, user friendly customization with real time electric and steam gauges, and actual versus forecast electric and steam consumption."
        , website: "www1.nyc.gov/assets/dcas/downloads/pdf/energy/energyinnovation/Phase1_5.pdf"
    },
    Tesla: {
        name: "Tesla"
        , type: "Project Partner"
        , date: "2015"
        , logo: Tesla
        , category: "Powerpack installation at NYC public school"
        , location: "Palo Alto, CA"
        , role: ""
        , customers: ""
        , overview: "Powerpack is a high-performance, infinitely scalable battery storage system for utility and commercial applications. Powerpack supports a host of applications that offer commercial consumers and energy providers greater control, efficiency and reliability across the electric grid. The Powerpack system scales to the space, power and energy requirements of any site, from small commercial businesses to regional utilities. It can be configured in various arrangements, offering far more modularity than competing models."
        , website: "www.tesla.com/powerpack"
    },
    Thinkeco: {
        name: "ThinkEco"
        , type: "Project Partner"
        , date: "2011"
        , logo: Thinkeco
        , category: "Energy industry advisory and strategy"
        , location: "New York, NY"
        , role: ""
        , customers: ""
        , overview: "ThinkEco, based in New York City, is a green technology company founded in 2008 with the mission to create a cost-effective energy efficiency solution. As a result, we developed the modlet, a self-installable solution that makes it simple to bring energy awareness and device-level energy management to home and office environments. The modlet was launched as a residential product in 2010 and has been available to commercial customers since early 2011. Since then, our offering has expanded with the development of the smartAC kit, a unique product to intelligently control plug-in air conditioners, and the creation of a diverse technology platform for third party hardware integration. The modlet platform is now being used by utilities across the nation to manage their peak system loads from a variety of devices that include room A/Cs and dehumidifiers."
        , website: "www.thinkecoinc.com"
    },
    UI: {
        name: "Utilities International"
        , type: "Project Partner"
        , date: "1999"
        , logo: UI
        , category: "Executive management dashboard tool for utility clients"
        , location: "Chicago, Illinois"
        , role: ""
        , customers: ""
        , overview: "Formed in 1994 and headquartered in Chicago, IL, Utilities International is the industry leader in planning, budgeting, regulatory, revenue and accounting solutions for the utility sector. During a period of increased challenges for utility companies, we provide our clients with the advantages of financial transparency, unparalleled performance and strong attention to customer service."
        , website: "www.utilitiesinternational.com"
    },
    CypressP: {
        name: "Cypress Envirosystems"
        , type: "Project Partner"
        , date: "2018"
        , logo: Cypress
        , category: "Non-invasive retrofit technologies"
        , location: "San Jose, CA"
        , role: ""
        , customers: ""
        , overview: "The Cypress Envirosystems Wireless Pneumatic Thermostat (WPT) retrofits an existing pneumatic thermostat to provide Direct Digital Control (DDC)-like zone control functionality at a fraction of the time and cost compared to a conventional DDC upgrade, without disturbing occupants. The WPT enables remote monitoring of zone temperature and branch pressure, remote control set points, and programmable setback or setup of the pneumatic HVAC systems. This functionality gives operators the ability to detect and diagnose faults that may cause energy waste or discomfort to occupants. It also enables integration with utility Demand Response programs."
        , website: "www1.nyc.gov/assets/dcas/downloads/pdf/energy/energyinnovation/Phase1_1.pdf"
    },
    RMI: {
        name: "Rocky Mountain Institute"
        , type: "Client"
        , date: "2020"
        , logo: RMI
        , category: "Built Environment Efficiency Research"
        , location: "Basalt, CO"
        , role: ""
        , customers: ""
        , overview: "Rocky Mountain Institute (RMI) is an organization in the United States dedicated to research, publication, consulting, and lecturing in the general field of sustainability, with a special focus on profitable innovations for energy and resource efficiency. RMI was established in 1982 and has grown into a broad-based institution with 220+ staff and an annual budget of some $52 million. RMI's work is independent and non-adversarial, with a strong emphasis on market-based solutions."
        , website: "www.rmi.org"
    },
    NBI: {
        name: "New Buildings Institute"
        , type: "Client"
        , date: "2020"
        , logo: NBI
        , category: "Built Environment Efficiency Research"
        , location: "Portland, OR"
        , role: ""
        , customers: ""
        , overview: "New Buildings Institute (NBI) is a nonprofit organization pushing for better energy performance in buildings. We work collaboratively with industry market players—governments, utilities, energy efficiency advocates and building professionals—to promote advanced design practices, innovative technologies, public policies and programs that improve energy efficiency. We also develop and offer guidance and tools to support the design and construction of energy efficient buildings."
        , website: "www.newbuildings.org"
    },
    EThree: {
        name: "Energy and Environmental Economics"
        , type: "Client"
        , date: "2020"
        , logo: EThree
        , category: "Built Environment Efficiency Research"
        , location: "New York, NY"
        , role: ""
        , customers: ""
        , overview: "Founded in 1989, Energy + Environmental Economics (E3) is a fast-growing energy consulting firm that helps utilities, regulators, policy makers, developers, and investors make the best strategic decisions possible as they implement new public policies, respond to technological advances, and address customers’ shifting expectations. Because E3 works with clients from all sectors of the electricity industry, we provide a 360-degree understanding of markets, planning, policy, regulation, and environmental factors. Just as important, we are committed to delivering clear, unbiased analyses that help clients make informed decisions."
        , website: "www.ethree.com"
    },
    Hygge: {
        name: "Hygge Power"
        , type: "Client"
        , date: "2021"
        , logo: Hygge
        , category: "Modular Energy Storage"
        , location: "Boulder, CO"
        , role: ""
        , customers: "Residential and commercial properties, aggregators, and Utilties"
        , overview: "Managing our power environment requires tools and insight that allow us to control everything from price, to efficiency and where our power comes from. Hygge Power products work for you and with you, to manage your power supply simply by plugging them in.  OPO, our revolutionary new power outlet, will transform how you use, save, and manage your electricity. Backup power. Energy storage. Smart outlet control. Energy reporting and analytics. All with the push of a button. Hygge Power is changing the future of energy."
        , website: "www.hyggepower.com/"
    },
    WM: {
        name: "West Monroe"
        , type: "Partner"
        , date: "2021"
        , logo: WM
        , category: "Energy Technology Consultants"
        , location: "Chicago, IL"
        , role: ""
        , customers: ""
        , overview: "West Monroe is a national consulting firm that was born in technology, and built for business, they partner with organizations who want to drive progress, and deliver results. West Monroe builds multidisciplinary teams that combine the industry and technology experience you need to address your challenges, and the operational skills to make sure change happens—and sticks. Bottom line: West Monroe's undeniably different approach—from idea all the way to impact—delivers real, tangible results. "
        , website: "www.westmonroe.com/"
    },
    LBL: {
        name: "Lawrence Berkeley National Lab"
        , type: "Client"
        , date: "2021"
        , logo: LBL
        , category: "U.S. National Energy Research Lab"
        , location: "Berkeley, CA"
        , role: ""
        , customers: ""
        , overview: "Lawrence Berkeley Lab fosters groundbreaking fundamental science that enables transformational solutions for energy and environment challenges, using interdisciplinary teams and by creating advanced new tools for scientific discovery. In the world of science, Berkeley Lab is synonymous with “excellence.” Research areas include Biosciences, Computing Sciences, Earth and Environmental Sciences, Energy Sciences, Energy Technologies, and Physical Sciences."
        , website: "www.lbl.gov/"
    },
    BP: {
        name: "BP Launchpad"
        , type: "Client"
        , date: "2021"
        , logo: BP
        , category: "Venture Investor"
        , location: "London, England"
        , role: ""
        , customers: ""
        , overview: "BP Launchpad generates sustainable change by building and scaling a progressive portfolio of digitally-led businesses which re-imagine energy. BP Launchpad blends the global scale of a corporate with the pace of a start-up. Being backed by BP means immidate sales opportunities, live datasets and test environments, coaching and access to a global network of customers, partners and vendors."
        , website: "www.bplaunchpad.com/"
    },
    Dynamo: {
        name: "Dynamo Energy Hub"
        , type: "Client"
        , date: "2021"
        , logo: BP
        , category: "Energy Hub and Ecosystem"
        , location: "New York, NY"
        , role: ""
        , customers: ""
        , overview: "Dynamo Energy Hub has a unique global network that provides industry thought leaders with a platform to accelerate the growth of the clean energy energy economy. Dynamo brings together traditional energy companies, governments, cleantech innovators, investors and entrepreneurs to shape the future of energy. Members and Sponsors gain exclusive access to Dynamo networking events and co-working hubs, benefitting from rich content and networking with experienced practitioners and energy industry thought leaders, all in state-of-the-art office environment."
        , website: "www.dynamoenergyhub.com/"
    },
    HEVO: {
        name: "HEVO Power"
        , type: "Client"
        , date: "2021"
        , logo: HEVO
        , category: "Wireless Electric Vehicle Charging"
        , location: "Brooklyn, NY"
        , role: ""
        , customers: "Property owners and fleets"
        , overview: "HEVO means Hybrid and Electric Vehicle Optimization. HEVO's mission is to enhance electric vehicle driving, charging and enjoyment through the HEVO Power Network, with the vision of serving as the global standard for universal, ubiquitous EV charging. The core values of HEVO are built into the letters of their name - Honesty, Effectiveness, Vision and Optimism. HEVO definitively believes in the three tenets of People, Planet and Power, which exemplify their purpose as an organization and community of deeply committed innovators. "
        , website: "www.hevo.com/"
    },
    NL: {
        name: "NewLab"
        , type: "Partner"
        , date: "2021"
        , logo: NL
        , category: "Innovation Studio"
        , location: "Brooklyn, NY"
        , role: ""
        , customers: ""
        , overview: "Newlab is home to over 155 companies, solving the world's biggest challenges with leaders and experts across industries and cities. They are champions of entreprenurship, innovation, and fronteir technologies that transform infrastructure and build a more resilient, sustainable world. "
        , website: "newlab.com/"
    },
    BGE: {
        name: "Baltimore Gas & Electric"
        , type: "Client"
        , date: "2021"
        , logo: BGE
        , category: "Utility"
        , location: "Baltimore, MD"
        , role: ""
        , customers: ""
        , overview: "Headquartered in Baltimore, BGE is Maryland’s largest natural gas and electric utility, delivering power to more than 1.3 million electric customers and more than 680,000 natural gas customers in central Maryland. With a history of more than 200 years of service as the first gas utility in the U.S., BGE has a long-standing commitment to our customers and the community. It began in 1816 when Rembrandt Peale lit the first gas lamp in Baltimore, and it continues two centuries later with BGE and our customers still leading the way in energy innovation."
        , website: "www.bge.com"
    },
    Stringbean: {
        name: "Stringbean Technologies"
        , type: "Client"
        , date: "2021"
        , logo: Stringbean
        , category: "Cloud based workflow management"
        , location: "Valley Stream, NY"
        , role: ""
        , customers: "Construction and building operation teams"
        , overview: "StringBean delivers customizable, cloud-based workflows – from planning to site inspections to mechanical maintenance and more – all backed by real-time analytics and reporting. With real time alerts, a simple workflow builder, task walkthroughs, and evidence of task completion, Stringbean helps manage vendors, inneficiencies, and risks."
        , website: "www.stringbean.tech"
    },
    Dollaride: {
        name: "Dollaride"
        , type: "Partner"
        , date: "2021"
        , logo: Dollaride
        , category: "Shared mobility"
        , location: "Brooklyn, NY"
        , role: ""
        , customers: ""
        , overview: "Dollar vans have played an integral part in NYC's transportation system for over 30 years. As the primary source of fast, reliable travel, thousands of New Yorkers currently use this commuter van network in their daily commutes. Dollaride provides innovative transportation technology to these marginalized communities through a cashless technology solution to grow this already vibrant commuter van network throughout NYC and beyond."
        , website: "www.dollaride.com"
    },
    BlocPower: {
        name: "BlocPower"
        , type: "Partner"
        , date: "2021"
        , logo: BlocPower
        , category: "Energy Solutions"
        , location: "Brooklyn, NY"
        , role: ""
        , customers: ""
        , overview: "BlocPower™ is a Brooklyn-based energy technology startup rapidly greening American cities. Since its founding in 2012, the company has completed energy projects in nearly 1,000 buildings and delivers results ahead of schedule and under budget. BlocPower™ utilizes its proprietary software for analysis, leasing, project management, and monitoring of urban clean energy projects and its customers are saving 20-40% on their energy bills each year. The company is backed by the world’s top investors, including Kapor Capital, one of Uber’s first investors, Andressen Horowitz, early investor in Facebook, Twitter, AirBnB and Lyft, the former Chairman of Google, and American Family Insurance Institute for Corporate and Social Impact."
        , website: "www.blocpower.io/"
    },
    RAP: {
        name: "Regulatory Assistance Project"
        , type: "Partner"
        , date: "2021"
        , logo: RAP
        , category: "Energy policy advisory"
        , location: "Montpelier, VT"
        , role: ""
        , customers: ""
        , overview: "The Regulatory Assistance Project (RAP)® is an independent, non-partisan, non-governmental organization dedicated to accelerating the transition to a clean, reliable, and efficient energy future. RAP’s global team has first-hand knowledge of the constraints and challenges policymakers face, and applies its collective insight to anticipate what they will face in the future. Building on peer-to-peer relationships, RAP helps energy and air quality decision-makers and stakeholders navigate the complexities of power sector policy, regulation, and markets. These policymakers and stakeholders depend on RAP staff for reliable and relevant information and assistance."
        , website: "www.raponline.org/"
    }
}

export class Home extends React.Component {

    constructor() {
        super()

        this.state = {
            menu: 0,
            popupWidth: 0,
            name: '',
            logo: '',
            type: '',
            category: '',
            location: '',
            overview: '',
            website: '',
            role: '',
            customers: '',
        }

        this.openClientPopup = this.openClientPopup.bind(this)
        this.closeClientPopup = this.closeClientPopup.bind(this)

    }

    openClientPopup(client) {

        const currentClient = clientInfo[client]

        this.setState({ menu: '800px' })
        this.setState({ popupWidth: '100vw' })
        this.setState({ name: currentClient.name })
        this.setState({ logo: currentClient.logo })
        this.setState({ type: currentClient.type })
        this.setState({ category: currentClient.category })
        this.setState({ location: currentClient.location })
        this.setState({ overview: currentClient.overview })
        this.setState({ website: currentClient.website })

        if (currentClient.type === 'Advisory Client') {
            this.setState({ customers: 'Target Customers: ' + currentClient.customers })
        } else {
            this.setState({ customers: '' })
        }

    }
    
    closeClientPopup() {
        this.setState({ menu: 0 })
        this.setState({ popupWidth: 0 })
    }

    scrollTo() {
        scroller.scrollTo('scroll-to-element', {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart'
        })
    }

    render() {
        return (
            <div>
                <ScrollToTopOnMount/>
                <div className="Homeimage">
                    <div className="HomeimageMask">
                        <div className="HometitleText">
                            <p className="HomemainTitle">Build <span style={{ color: 'rgb(11, 108, 254)' }}>Smarter.</span></p>
                            <p className="HomemainTitle">Build <span style={{ color: 'rgb(11, 108, 254)' }}>Cleaner.</span></p>
                            <p className="HomemainTitle">Build <span style={{ color: 'rgb(11, 108, 254)' }}>Edison.</span></p>
                            <p className="Homesubtitle">Helping cleantech companies, clients, and investors grow.</p>
                            <a href='/#/contact/'>
                                <div className='HomemainContactLink'>
                                    <p className='HomecontactLinkText'>Contact Us</p>
                                    <img className="HomeblueArrowIcon" src={blueArrowIcon} alt='' />
                                </div>
                            </a>
                            <Link activeClass="active" className="scroll" to="content" spy={true} smooth={true} duration={500} ><img className="HomewhiteArrowIcon" src={whiteArrowIcon} alt='' /></Link>
                        </div>
                    </div>
                </div>

                <div className='homeFirstContainer'>
                    <Element name="content" className="element" >
                        <div className='homeContentContainer'>
                            <div className="homeTextcontainer">
                                <h2 className="HomecontactTagline">Growing technologies<br/>into companies<span style={{ color: 'rgb(11, 108, 254)' }}>.</span></h2>
                                <h2 className="HomeText">Based in New York, Build Edison leverages decades of experience and established partnerships to grow innovative technologies into revenue generating businesses.  We offer a consistent path to commercial viability, matching exceptional cleantechs to customers and funding while preparing them for growth.</h2>
                                <a href='/#/about/'><div className='HomemainContactLink' style={{ height: '40px'}}>
                                    <p className='HomecontactLinkText' style={{ color: 'black', marginTop: '8px' }}>About Us</p>
                                    <img className="HomeblueArrowIcon" src={blueArrowIcon} alt='' style={{ marginTop: '10px', marginLeft: '77px' }} />
                                </div></a>
                            </div>
                            <div className='homeIconContainer'>
                                <img className='HomeIcon' style={{ marginRight: '40px' }} src={StartupIcon} alt='' />
                            </div>
                        </div>
                        <div className='homeContentContainer'>
                            <div className="homeTextcontainerRight">
                                <h2 className="HomecontactTaglineRight">Connecting the<br />energy market<span style={{ color: 'rgb(11, 108, 254)' }}>.</span></h2>     
                                <h2 className="HomeTextRight">We provide services to three critical elements of the clean energy market: cleantech companies, large energy customers, and investors.  As an investment and consulting firm, Build Edison offers value through funding, expertise, and connections at every level of the production chain.</h2>
                                <a href='/#/solutions/' style={{height: '50px'}}><div className='HomemainContactLinkRight'>
                                    <p className='HomecontactLinkText' style={{ color: 'black', marginTop: '8px' }}>Solutions</p>
                                    <img className="HomeblueArrowIcon" src={blueArrowIcon} alt='' style={{ marginTop: '10px', marginLeft: '80px' }} />
                                </div></a>
                            </div>
                            <div className='homeIconContainerRight'>
                                <img className='HomeIconRight' style={{ marginLeft: '40px', marginTop: '160px' }} src={NetworkIcon} alt='' />
                            </div>
                            <div style={{ clear: 'both'}}></div>
                        </div>
                        <div className='homeContentContainer'>
                            <div className="homeTextcontainer">
                                <h2 className="HomecontactTagline" style={{ marginTop: '70px' }}>Based in New York<span style={{ color: 'rgb(11, 108, 254)' }}>.</span></h2>
                                <h2 className="HomeText">New York has instituted one of the most innovative and ambitious clean energy programs in the country, commiting billions of dollars to become 100% renewable by 2040 among other goals.  BE’s team has decades of experience in New York energy policy, and can help investors and cleantechs capitalize on this major opportunity.</h2>
                                <a href='/#/team/'><div className='HomemainContactLink' style={{ height: '40px' }}>
                                    <p className='HomecontactLinkText' style={{ color: 'black', marginTop: '8px' }}>Our Team</p>
                                    <img className="HomeblueArrowIcon" src={blueArrowIcon} alt='' style={{ marginTop: '10px', marginLeft: '81px'  }} />
                                </div></a>
                            </div>
                            <div className='homeIconContainer'>
                                <img className='HomeIcon' style={{ marginRight: '62.5px', marginTop: '160px' }} src={NYIcon} alt='' />
                            </div>
                        </div>
                    </Element>
                </div>
                <div className='darkMask' style={{ width: this.state.popupWidth }} onClick={this.closeClientPopup}></div>
                <div className="clientPopup" style={{ maxWidth: this.state.menu }}>
                    <img src={close} alt='' onClick={this.closeClientPopup} className='closeButton' />
                    <img className='clientPopupImg' src={this.state.logo} alt='' />
                    <p className="clientName">{this.state.name}</p>
                    <p className="clientSince">{this.state.type}</p>
                    <h2 className='ClientInfoTitle' style={{ marginTop: '40px' }}>Category<span style={{ color: 'rgb(11, 108, 254)' }}>.</span></h2>
                    <p className="ClientInfo">{this.state.category}</p>
                    <h2 className='ClientInfoTitle'>Location<span style={{ color: 'rgb(11, 108, 254)' }}>.</span></h2>
                    <p className="ClientInfo">{this.state.location}</p>
                    <h2 className='ClientInfoTitle'>Overview<span style={{ color: 'rgb(11, 108, 254)' }}>.</span></h2>
                    <p className="ClientInfo">{this.state.overview}<br /><br />{this.state.customers}</p>
                    <h2 className='ClientInfoTitle' style={{ marginBottom: '10px' }}>Explore<span style={{ color: 'rgb(11, 108, 254)' }}>.</span></h2>
                    <div className='clientInfoLinkSpacing'>
                        <a href={'http://' + this.state.website} target="_blank" rel="noopener noreferrer" className='clientInfoLink'>{this.state.website}</a>
                    </div>
                    <div className='spacing' style={{ width: '100%', height: '150px' }}></div>
                </div>
                
                <div className='homeLogosContainer' style={{marginTop: '10px'}}>
                    <div className='HomeLogosBackgroundImg'>
                        <div className='HomeLogosBackgroundImgMask'>
                            <div className='spacing'></div>
                            <div className='homeLogosContainerBackground' >
                                <div className='HomeLogosContainer'>
                                    <h2 className="HomeLogosTitle">Clients and Partners<span style={{ color: 'rgb(11, 108, 254)' }}>.</span></h2>
                                    
                                    <div className='logoContainer' onClick={() => this.openClientPopup('Aclima')}>
                                        <span className="helper"></span>
                                        <img className='clientLogo' src={Aclima} alt='' />
                                    </div>
                                    <div className='logoContainer' onClick={() => this.openClientPopup('BGE')}>
                                        <span className="helper"></span>
                                        <img className='clientLogoWide' src={BGE} alt='' />
                                    </div>
                                    <div className='logoContainer' onClick={() => this.openClientPopup('Dynamo')}>
                                        <span className="helper"></span>
                                        <img className='clientLogoWide' src={Dynamo} alt='' />
                                    </div>
                                    <div className='logoContainer' onClick={() => this.openClientPopup('Enverid')}>
                                        <span className="helper"></span>
                                        <img className='clientLogoWide' src={Enverid} alt='' />
                                    </div>
                                    <div className='logoContainer' onClick={() => this.openClientPopup('EThree')}>
                                        <span className="helper"></span>
                                        <img className='clientLogoRound' src={EThree} alt='' />
                                    </div>
                                    <div className='logoContainer' onClick={() => this.openClientPopup('GCap')}>
                                        <span className="helper"></span>
                                        <img className='clientLogoWide' src={GCap} alt='' />
                                    </div>
                                    <div className='logoContainer' onClick={() => this.openClientPopup('Hersha')}>
                                        <span className="helper"></span>
                                        <img className='clientLogoWide' src={Hersha} alt='' />
                                    </div>
                                    <div className='logoContainer' onClick={() => this.openClientPopup('HEVO')}>
                                        <span className="helper"></span>
                                        <img className='clientLogoWide' src={HEVO} alt='' />
                                    </div>
                                    <div className='logoContainer' onClick={() => this.openClientPopup('Hygge')}>
                                        <span className="helper"></span>
                                        <img className='clientLogoWide' src={Hygge} alt='' />
                                    </div>
                                    <div className='logoContainer' onClick={() => this.openClientPopup('Kilroy')}>
                                        <span className="helper"></span>
                                        <img className='clientLogoRound' src={Kilroy} alt='' />
                                    </div>
                                    <div className='logoContainer' onClick={() => this.openClientPopup('LBL')}>
                                        <span className="helper"></span>
                                        <img className='clientLogoRound' src={LBL} alt='' />
                                    </div>
                                    <div className='logoContainer' onClick={() => this.openClientPopup('Murata')}>
                                        <span className="helper"></span>
                                        <img className='clientLogoWide' src={Murata} alt='' />
                                    </div>
                                    <div className='logoContainer' onClick={() => this.openClientPopup('NYSERDA')}>
                                        <span className="helper"></span>
                                        <img className='clientLogoWide' src={NYSERDA} alt='' />
                                    </div>
                                    <div className='logoContainer' onClick={() => this.openClientPopup('RMI')}>
                                        <span className="helper"></span>
                                        <img className='clientLogoRound' src={RMI} alt='' />
                                    </div>
                                    <div className='logoContainer' onClick={() => this.openClientPopup('Wex')}>
                                        <span className="helper"></span>
                                        <img className='clientLogoWide' src={Wex} alt='' />
                                    </div>
                                    <div className='logoContainer' onClick={() => this.openClientPopup('WM')}>
                                        <span className="helper"></span>
                                        <img className='clientLogoWide' src={WM} alt='' />
                                    </div>
                                    <div className='SolutionslowerContactButtonContainer'>
                                        <a href='/#/clients-and-partners/' style={{ textDecoration: 'none' }}>
                                            <p className="SolutionslowerContactButton" style={{ textDecoration: 'none' }}>View More</p>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}